import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';

import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as QRCode from 'qrcode';

import { GerinusService } from '../lib/gerinus.service';

import { interRegular } from '../../../assets/fonts/inter/Inter-Regular-normal';
import { interSemiBold } from '../../../assets/fonts/inter/Inter-SemiBold-normal';

import { ICancelRequestSchema, IChangeRequestDescriptionSchema, Request } from 'src/app/interfaces/request';
import { RequestStatusEnum } from 'src/app/Enums/RequestStatusEnum';

import { environment } from 'src/environments/environment';
import { IStepOption } from 'src/app/interfaces/stepOption';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GlobalService } from '../lib/global.service';

export interface IStepsFormRequest {
    requestData: IStepOption,
    forms: IStepOption,
    attachment: IStepOption,
    summary: IStepOption
}

@Injectable()
export class RequestService extends GerinusService {

    constructor(
        public http: HttpClient,
        private datePipe: DatePipe,
        private global: GlobalService
    ) {
        super(http);
        this.entityName = 'solicitacao';
        this.idField = 'SOL_CodigoSolicitacao';
        this.model = 'SolicitacaoModel';
    }

    public generateRequestPDF(request: Request) {
        const doc = new jsPDF('p', 'mm', 'a4');

        const logoProtocolize = new Image();
        const urlPublicRequest = environment.projectURL + '/free/solicitacao/' + request.SOL_CodigoSolicitacao;
        let qrcode: string;

        QRCode.toDataURL(urlPublicRequest, (_, url) => {
            var base64Data = url.split(',')[1];
            qrcode = atob(base64Data);
        });

        doc.addImage(qrcode, 'PNG', 166, 220, 23, 22);

        logoProtocolize.src = this.global.getTheme().logo;
        doc.addImage(logoProtocolize, 'png', 72, 5, 60, 18);

        doc.addFileToVFS('Inter-Bold.ttf', interSemiBold);
        doc.addFont('Inter-Bold.ttf', 'Inter-Bold', 'normal');
        doc.setFont('Inter-SemiBold');

        doc.addFileToVFS('Inter-Regular.ttf', interRegular);
        doc.addFont('Inter-Regular.ttf', 'Inter-Regular', 'normal');

        doc.setFont('Inter-SemiBold');
        doc.setFontSize(16);
        doc.setTextColor('#0A2156');
        doc.text('Solicitação N° ' + request.SOL_NumeroSolicitacao + '/' + request.SOL_AnoSolicitacao, 103, 35, {
            align: 'center',
        });

        const requestDateFormatted = this.datePipe.transform(request.SOL_DataSolicitacao, 'dd/MM/yyyy');

        doc.setFont('Inter-Regular');
        doc.setFontSize(12);
        doc.setTextColor('#687489');
        doc.text('Data da solicitação: ' + requestDateFormatted, 103, 42, {
            align: 'center',
        });

        autoTable(doc, {
            body: [
                [
                    {
                        content: 'Solicitante:',
                        styles: {
                            halign: 'left',
                            valign: 'middle',
                            font: 'Inter-SemiBold',
                            cellWidth: 'wrap',
                            fontSize: 10,
                            textColor: '#0a2156',
                            cellPadding: { left: 4, top: 2, right: 4 },
                        },
                    },
                ],
                [
                    {
                        content: request.SOLR_NomeRazaoSocial
                            ? `${this.formatCpfCnpj(request.SOLR_CpfCnpj)} - ${request.SOLR_NomeRazaoSocial}`
                            : `${this.formatCpfCnpj(request.PE_CPF)} - ${request.PE_Nome}`,
                        styles: {
                            halign: 'left',
                            fontSize: 12,
                            textColor: '#687489',
                            cellPadding: {
                                left: 4,
                                top: 2,
                                right: 4,
                                bottom: 2,
                            },
                        },
                    },
                ],
            ],
            theme: 'plain',
            startY: 55,
            styles: {
                fillColor: '#f2f5f9',
            },
        });

        autoTable(doc, {
            body: [
                [
                    {
                        content: 'Órgão:',
                        styles: {
                            halign: 'left',
                            valign: 'middle',
                            font: 'Inter-SemiBold',
                            cellWidth: 'wrap',
                            fontSize: 10,
                            textColor: '#0a2156',
                            cellPadding: { left: 4, top: 2, right: 4 },
                        },
                    },
                    {
                        content: 'Assunto:',
                        styles: {
                            halign: 'left',
                            valign: 'middle',
                            font: 'Inter-SemiBold',
                            cellWidth: 'auto',
                            fontSize: 10,
                            textColor: '#0a2156',
                            cellPadding: { left: 4, top: 2, right: 4 },
                            lineWidth: { left: 1, top: 0, right: 0, bottom: 0 },
                            lineColor: '#ffffff',
                        },
                    },
                ],
                [
                    {
                        content: request.OR_Descricao,
                        styles: {
                            halign: 'left',
                            fontSize: 12,
                            textColor: '#687489',
                            cellPadding: {
                                left: 4,
                                top: 2,
                                right: 4,
                                bottom: 2,
                            },
                        },
                    },
                    {
                        content: request.AS_Descricao,
                        styles: {
                            halign: 'left',
                            fontSize: 12,
                            textColor: '#687489',
                            cellPadding: {
                                left: 4,
                                top: 2,
                                right: 4,
                                bottom: 2,
                            },
                            lineWidth: { left: 1, top: 0, right: 0, bottom: 0 },
                            lineColor: '#ffffff',
                        },
                    },
                ],
            ],
            theme: 'plain',
            startY: 71,
            styles: {
                fillColor: '#f2f5f9',
            },
        });

        if (request.SOLR_CpfCnpj) {
            autoTable(doc, {
                body: [
                    [
                        {
                            content: 'Observações:',
                            styles: {
                                halign: 'left',
                                valign: 'middle',
                                font: 'Inter-SemiBold',
                                cellWidth: 'wrap',
                                fontSize: 10,
                                textColor: '#0a2156',
                                cellPadding: { left: 4, top: 2, right: 4 },
                            },
                        },
                    ],
                    [
                        {
                            content: 'A Solicitação foi realizada por Terceiros.',
                            styles: {
                                halign: 'left',
                                fontSize: 12,
                                textColor: '#687489',
                                cellPadding: {
                                    left: 4,
                                    top: 2,
                                    right: 4,
                                    bottom: 2,
                                },
                            },
                        },
                    ],
                ],
                theme: 'plain',
                startY: 87,
                styles: {
                    fillColor: '#f2f5f9',
                },
            });
        }

        autoTable(doc, {
            body: [
                [
                    {
                        content: 'Descrição da solicitação:',
                        styles: {
                            halign: 'left',
                            valign: 'middle',
                            font: 'Inter-SemiBold',
                            cellWidth: 'wrap',
                            fontSize: 10,
                            textColor: '#0a2156',
                            cellPadding: { left: 4, top: 2, right: 4 },
                        },
                    },
                ],
                [
                    {
                        content: request.SOL_Descricao,
                        styles: {
                            halign: 'left',
                            fontSize: 12,
                            textColor: '#687489',
                            cellPadding: {
                                left: 4,
                                top: 2,
                                right: 4,
                                bottom: 2,
                            },
                        }
                    },
                ],
            ],
            theme: 'plain',
            startY: request.SOLR_CpfCnpj ? 100 : 90,
            styles: {
                fillColor: '#f2f5f9',
            },
        });

        autoTable(doc, {
            body: [
                [
                    {
                        content: 'Situação:',
                        styles: {
                            halign: 'center',
                            valign: 'middle',
                            font: 'Inter-SemiBold',
                            cellWidth: 30,
                            fontSize: 12,
                            cellPadding: {
                                bottom: 0,
                                top: 0,
                                right: 0,
                                left: 0,
                            },
                            textColor: '#0A2156',
                        },
                    },
                    {
                        content: this.getStatusDescription(request.SOL_Status),
                        styles: {
                            halign: 'left',
                            fontSize: 12,
                            cellPadding: 3,
                            textColor: '#687489',
                        },
                    },
                    {
                        content: '',
                        rowSpan: 2,
                        styles: {
                            halign: 'center',
                            valign: 'middle',
                            fontSize: 12,
                            cellPadding: 6,
                            textColor: '#687489',
                        },
                    },
                ],
                [
                    {
                        content: 'Chave de verificação:',
                        styles: {
                            halign: 'center',
                            valign: 'middle',
                            font: 'Inter-SemiBold',
                            cellWidth: 50,
                            fontSize: 12,
                            cellPadding: {
                                bottom: 0,
                                top: 0,
                                right: 0,
                                left: 0,
                            },
                            textColor: '#0A2156',
                        },
                    },
                    {
                        content: request.SOL_ChaveAutenticidade,
                        styles: {
                            halign: 'left',
                            fontSize: 12,
                            cellPadding: 3,
                            textColor: '#687489',
                        },
                    },
                ],
                [
                    {
                        content:
                            'Acompanhe sua solicitação acessando https://web.protocolize.pmsga.ce.gov.br/principal ou aponte a câmera do seu celular para o QR Code ao lado.',
                        colSpan: 3,
                        styles: {
                            halign: 'left',
                            fontSize: 12,
                            cellPadding: 3,
                            textColor: '#687489',
                        },
                    },
                ],
            ],
            theme: 'plain',
            startY: 220,
            styles: {
                lineWidth: 0.2,
                lineColor: '#687489',
                font: 'Inter-Regular',
            },
        });

        const logoSga = new Image();
        logoSga.src = this.global.getTheme().logo;
        doc.addImage(logoSga, 'png', 60, 272, 35, 14);

        const line = new Image();
        line.src = '../../assets/img/brand/line.png';
        doc.addImage(line, 'png', 100, 275, 0.2, 10);

        doc.setFont('Inter-Regular');
        doc.setFontSize(12);
        doc.setTextColor('#0A2156');
        doc.text(request.OR_Descricao, 107, 279, { maxWidth: 40 });

        return doc.save(`Comprovante de Solicitação - N° ${request.SOL_CodigoSolicitacao}`);
    }

    getStatusDescription(requestStatus: number) {
        const statusDescriptions = {
            0: 'Em Processo de Triagem',
            1: 'Solicitação Aceita',
            2: 'Correção de Pendências',
            3: 'Anulada',
            4: 'Corrigida'
        };

        return statusDescriptions[requestStatus];
    }

    public isInEvaluation(status: number): boolean {
        return RequestStatusEnum.evaluation == status;
    }

    public isInCorrection(status: number): boolean {
        return RequestStatusEnum.correction == status;
    }

    public isAccepted(status: number): boolean {
        return RequestStatusEnum.accepted == status;
    }

    public viewPublicRequest(id: number) {
        return this.http.get(this.apiURL() + '/api/free/solicitacao/' + id);
    }

    public cancelRequest(data: ICancelRequestSchema): Observable<any> {
        return this.http.post(this.apiURL() + '/api/requests/cancel-request', data, this.headers())
            .pipe(
                catchError(this.handleError)
            );
    }

    private handleError(error: any): Observable<never> {
        console.error('An error occurred:', error);
        return throwError(() => new Error('Something went wrong; please try again later.'));
    }

    public updateProcessDescription(data: IChangeRequestDescriptionSchema): Observable<any> {
        return this.http.post(this.apiURL() + '/api/requests/edit-description', data, this.headers())
            .pipe(
                catchError(this.handleError)
            );
    }
}
