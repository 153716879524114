import { AfterViewInit, Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import AOS from 'aos';
import 'aos/dist/aos.css';

@Component({
  selector: 'app-collapse',
  templateUrl: './collapse.component.html',
  styleUrls: ['./collapse.component.scss']
})
export class CollapseComponent implements OnInit {

  @ViewChild('accordionItem') accordionItems: ElementRef;

  @Input() elementId = 1;

  ngOnInit(): void {
    AOS.init();
  }

  toggleAccordion(): void {
    const button = this.accordionItems.nativeElement.querySelector('.accordion-button');
    const content = this.accordionItems.nativeElement.querySelector('.accordion-content');
    const expanded = button.getAttribute('aria-expanded') === 'true';

    button.setAttribute('aria-expanded', (!expanded).toString());
    content.style.maxHeight = expanded ? null : content.scrollHeight + 'px';
  }
}
