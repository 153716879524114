import { Component } from '@angular/core';
import FormRequest from '../common/formRequest';
import { FormBuilder, Validators } from '@angular/forms';
import { FiscoService } from 'src/app/app-core/services/fisco.service';
import { GlobalService } from 'src/app/app-core/lib/global.service';

@Component({
  selector: 'app-form-request-default',
  templateUrl: './form-request-default.component.html',
  styleUrls: ['./form-request-default.component.scss']
})
export class FormRequestDefaultComponent extends FormRequest {

  constructor(
    public fb: FormBuilder,
    public fiscoService: FiscoService,
  ) {
    super(fb, fiscoService);
    this._initForm();
    this.formChanges();
  }

  private _initForm(): void {
    const formVariants = this.fb.group({
      SOL_Descricao: ["", Validators.required],
      termoAceito: [null, Validators.requiredTrue]
    });

    this.form.setControl("formVariants", formVariants);
  };
}
