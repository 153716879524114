import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import FormRequest from '../common/formRequest';
import { iptuFormEnum } from 'src/app/Enums/SpecificFormInputEnum';
import { CepService } from 'src/app/app-core/lib/cep.service';
import getDescription from '../utilities/descriptionIptu';
import { EnderecoFormComponent } from 'src/app/components/endereco-form/endereco-form.component';
import { FiscoService } from 'src/app/app-core/services/fisco.service';

enum descriptionRadioButtonEnum {
	IPTU_REQUERIMENTO_IMUNIDADE_DE_IPTU = 1,
	IPTU_ISENÇÃO_VIÚVAS = 2,
	IPTU_REQUERIMENTO_DE_IPTU = 3,
}

@Component({
	selector: 'app-form-request-iptu',
	templateUrl: './form-request-iptu.component.html',
	styleUrls: ['./form-request-iptu.component.scss'],
	providers: [CepService]
})
export class FormRequestIptuComponent extends FormRequest implements OnInit, OnChanges {

	@ViewChild('checkApplicantData') checkbox: ElementRef;
	@ViewChild(EnderecoFormComponent) enderecoFormComponent: EnderecoFormComponent;

	@Input() applicantDetails;

	public listApplicantData = [
		iptuFormEnum.IPTU_ISENÇÃO_IPTU_DOENÇAS_GRAVES,
		iptuFormEnum.IPTU_REQUERIMENTO_DE_IPTU
	];

	public listApplicantIdentification = [
		iptuFormEnum.IPTU_ISENÇÃO_VIÚVAS,
		iptuFormEnum.IPTU_ISENÇÃO_CEDIDO_GRATUITAMENTE,
		iptuFormEnum.IPTU_ISENÇÃO_SERVIDOR_PÚBLICO,
		iptuFormEnum.IPTU_ISENÇÃO_LOCADO_NO_MUNICÍPIO,
	]

	public listApplicantCompany = [
		iptuFormEnum.IPTU_ISENÇÃO_SEM_FINS_LUCRATIVOS,
	]

	public listScienceTerm = [
		iptuFormEnum.IPTU_ISENÇÃO_LOCADO_NO_MUNICÍPIO,
		iptuFormEnum.IPTU_ISENÇÃO_SERVIDOR_PÚBLICO,
		iptuFormEnum.IPTU_ISENÇÃO_CEDIDO_GRATUITAMENTE,
		iptuFormEnum.IPTU_ISENÇÃO_SEM_FINS_LUCRATIVOS,
		iptuFormEnum.IPTU_ISENÇÃO_VIÚVAS
	];

	public termVeracity = [
		iptuFormEnum.IPTU_REQUERIMENTO_IMUNIDADE_DE_IPTU,
		iptuFormEnum.IPTU_ISENÇÃO_IPTU_DOENÇAS_GRAVES,
		iptuFormEnum.IPTU_REQUERIMENTO_DE_IPTU
	]

	public solipDescricao: string;
	public estados: string[];
	public municipiosPorEstado: string[];
	public listEstadoCivil = ['Solteiro', 'Casado', 'Viúvo', 'Divorciado', 'Separado'];

	public listRequestDescription: string[][] = []
	public haveDescription = false;
	public othersDescription = false;
	private subjectChangedOnce = false;

	constructor(
		public fb: FormBuilder,
		private cepService: CepService,
		public fiscoService: FiscoService,
	) {
		super(fb, fiscoService);
		this.form = this.fb.group({});
		this.formChanges();
	}

	ngOnInit(): void {
		this.renderForm();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.subject && this.subjectChangedOnce) {
			this.resetFormGroupsBySubject();
			console.log('[form-request.ngOnChanges] this.form: ', this.subject)
		}
		else {
			this.subjectChangedOnce = true;
		}
	}

	private handleFindCepProperty() {
		this.form.get('propertyData').get('SOLIP_CEP').valueChanges.subscribe({
			next: (value: string) => {
				if (value?.length > 7) {
					this.cepService.getEnderecoByCep(value).subscribe({
						next: (response: any) => {
							this.form.get('propertyData').patchValue({
								SOLIP_Bairro: response.bairro,
								SOLIP_Logradouro: response.logradouro
							})
							this.form.updateValueAndValidity();
						}
					});
				}
			}
		});
	}
	private renderForm() {
		this.setFormVariants();
		this.setListRequestDescription();
		if (!this.haveSubject([iptuFormEnum.IPTU_REQUERIMENTO_IMUNIDADE_DE_IPTU])) {
			this.setGroupPropertyData();
		}

		if (this.haveSubject(this.listApplicantData)) {
			this.setGroupApplicantData();
		}
		if (this.haveSubject(this.listApplicantIdentification)) {
			this.setApplicantIdentification();
		}
		if (this.haveSubject(this.listApplicantCompany)) {
			this.setApplicantCompany();
		}
		if (this.haveSubject([iptuFormEnum.IPTU_ISENÇÃO_IPTU_DOENÇAS_GRAVES])) {
			this.setDiseaseData();
		}
		if (this.form.get('propertyData')) {
			this.handleFindCepProperty();
		}
		if (this.haveSubject([iptuFormEnum.IPTU_REQUERIMENTO_IMUNIDADE_DE_IPTU])) {
			this.setImmunityData();
		}


	}

	private setGroupPropertyData() {
		const propertyData: FormGroup = this.fb.group({
			SOLIP_TipoImovel: ['Urbano', Validators.required],
			SOLIP_InscricaoImovel: ['', Validators.required],
			SOLIP_UF: ['CE'],
			SOLIP_Logradouro: ['', Validators.required],
			SOLIP_Complemento: [''],
			SOLIP_Bairro: ['', Validators.required],
			SOLIP_CEP: ['', Validators.required],
			SOLIP_Numero: ['', Validators.required],
			SOLIP_Municipio: [''],
			SOLIP_Descricao: ['']
		})

		if (this.subject == iptuFormEnum.IPTU_REQUERIMENTO_IMUNIDADE_DE_IPTU || this.subject == iptuFormEnum.IPTU_REQUERIMENTO_DE_IPTU) {
			propertyData.addControl('SOLIP_Cartografia', this.fb.control(''));
		}

		this.form.setControl('propertyData', propertyData);
	}

	private setFormVariants() {
		const formVariants = this.fb.group({
			SOL_Descricao: [''],
			termCheck: [false, Validators.requiredTrue]
		})
		this.form.setControl('formVariants', formVariants);
		this.checkDescriptionRequest();
	}

	private setGroupApplicantData() {
		const applicantData = this.fb.group({
			SOLIP_RequerenteNomeRazaoSocial: [this.applicantDetails.SOLR_NomeRazaoSocial],
			SOLIP_RequerenteEmail: [this.applicantDetails.SOLR_Email],
			SOLIP_RequerenteCpfCnpj: [this.applicantDetails.SOLR_CpfCnpj],
			SOLIP_RequerenteRG: [''],
			SOLIP_RequerenteTelefone: ['', Validators.required],
			SOLIP_RequerenteUF: ['', Validators.required],
			SOLIP_RequerenteMunicipio: ['', Validators.required],
			SOLIP_RequerenteLogradouro: ['', Validators.required],
			SOLIP_RequerenteNumero: ['', Validators.required],
			SOLIP_RequerenteComplemento: [''],
			SOLIP_RequerenteBairro: ['', Validators.required],
			SOLIP_RequerenteCEP: ['', [Validators.required, Validators.min(8)]]
		})
		this.form.setControl('applicantData', applicantData);
		this.handleFindCepProperty();
	}

	private setApplicantIdentification() {
		const applicant = this.fb.group({
			SOLIP_RequerenteNomeRazaoSocial: [this.applicantDetails.SOLR_NomeRazaoSocial],
			SOLIP_RequerenteEmail: [this.applicantDetails.SOLR_Email],
			SOLIP_RequerenteCpfCnpj: [this.applicantDetails.SOLR_CpfCnpj],
			SOLIP_RequerenteRG: ['', Validators.required],
			SOLIP_RequerenteTelefone: ['', Validators.required],
			SOLIP_RequerenteEstadoCivil: ['']
		})
		this.form.setControl('applicantIdentification', applicant);
	}

	private setApplicantCompany() {
		const applicant = this.fb.group({
			SOLIP_RequerenteNomeRazaoSocial: ['', Validators.required],
			SOLIP_RequerenteEmail: ['', Validators.required],
			SOLIP_RequerenteCpfCnpj: ['', Validators.required],
			SOLIP_RequerenteCNAE: ['', Validators.required],
			SOLIP_RequerenteTelefone: ['', Validators.required],
		})
		this.form.setControl('applicantCompany', applicant);
	}

	private setDiseaseData() {
		const diseaseData = this.fb.group({
			SOLIP_Doenca: ['', Validators.required],
			SOLIP_DoenteNome: ['', Validators.required],
			SOLIP_DoenteCpf: ['', Validators.required],
			SOLIP_DoenteRG: ['', Validators.required],
			SOLIP_DoenteVinculo: [''],
		});

		this.form.setControl('diseaseData', diseaseData);
	}

	private setImmunityData() {
		const immunityData = this.fb.array([
			this.fb.group({
				SOLII_InscricaoImovel: ['', Validators.required],
				SOLII_Endereco: ['', Validators.required],
				SOLII_CEP: ['', Validators.required],
			})
		]);
		const immunityRequest = this.fb.group({
			SOLIP_RequerenteNomeRazaoSocial: ['', Validators.required],
			SOLIP_RequerenteEmail: ['', Validators.required],
			SOLIP_RequerenteCpfCnpj: ['', Validators.required],
			SOLIP_RequerenteTelefone: ['', Validators.required],
			SOLIP_RequerenteRepresentanteNome: ['', Validators.required],
			SOLIP_RequerenteRepresentanteCpf: ['', Validators.required],
			SOLIP_RequerenteInscricaoIss: ['', Validators.required],
			SOLIP_RequerenteUF: ['', Validators.required],
			SOLIP_RequerenteMunicipio: ['', Validators.required],
			SOLIP_RequerenteLogradouro: ['', Validators.required],
			SOLIP_RequerenteNumero: ['', Validators.required],
			SOLIP_RequerenteComplemento: [''],
			SOLIP_RequerenteBairro: ['', Validators.required],
			SOLIP_RequerenteCEP: ['', [Validators.required, Validators.min(8)]],
			SOLIP_Descricao: [null, Validators.required]
		});
		console.log("[setImunnityData] controls: ", this.form.controls.immunityRequest);

		this.form.setControl('immunityData', immunityData);
		this.form.setControl('immunityRequest', immunityRequest);
	}

	public addGroupImmunity() {
		const groupImmunity = this.fb.group({
			SOLII_InscricaoImovel: ['', Validators.required],
			SOLII_Endereco: ['', Validators.required],
			SOLII_CEP: ['', Validators.required],
		});

		console.log("[addGroupImmunity] immunityData: ", this.immunityData);

		this.immunityData.push(groupImmunity);
	}

	public removeGroupImmunity(index: number) {
		(this.form.get('immunityData') as FormArray).removeAt(index);
	}

	onCheckboxChange(formGroupName: string) {
		const formGroup = this.form.get(formGroupName);

		if (this.checkbox.nativeElement.checked) {
			formGroup.patchValue({
				SOLIP_RequerenteCpfCnpj: this.applicantDetails.SOLR_CpfCnpj,
				SOLIP_RequerenteEmail: this.applicantDetails.SOLR_Email,
				SOLIP_RequerenteNomeRazaoSocial: this.applicantDetails.SOLR_NomeRazaoSocial
			});
		} else {
			formGroup.patchValue({
				SOLIP_RequerenteCpfCnpj: '',
				SOLIP_RequerenteEmail: '',
				SOLIP_RequerenteNomeRazaoSocial: ''
			});
		}
		this.form.updateValueAndValidity();
	}

	setSolipDescription(value: string) {
		console.log("[form-request-iptu.setSolipDescription] value: ", value)
		if (value == "Outros") {
			this.form.get('propertyData').get('SOLIP_Descricao').patchValue('')
			this.othersDescription = true;
		}
		else {
			this.form.get('propertyData').get('SOLIP_Descricao').patchValue(value)
			this.othersDescription = false;
		}
	}

	getEnumIptu(type: string): string {
		return iptuFormEnum[type];
	}

	setListRequestDescription() {

		this.listRequestDescription[descriptionRadioButtonEnum.IPTU_REQUERIMENTO_IMUNIDADE_DE_IPTU] = [
			'TEMPLO RELIGIOSO',
			'PARTIDO POLÍTICO',
			'INSTITUIÇÃO DE ASSISTÊNCIA SOCIAL E INSTITUIÇÃO DE EDUCAÇÃO',
			'ÓRGÃO PÚBLICO',
			'SINDICATO DOS TRABALHADORES'
		];

		this.listRequestDescription[descriptionRadioButtonEnum.IPTU_ISENÇÃO_VIÚVAS] = [
			'PESSOA VIÚVA',
			'PESSOA ÓRFÃ',
			'PESSOA APOSENTADA PENSIONISTA',
			'PESSOA INVÁLIDA PERMANENTEMENTE PARA TRABALHO'
		]

		this.listRequestDescription[descriptionRadioButtonEnum.IPTU_REQUERIMENTO_DE_IPTU] = [
			'Alteração de área de construção',
			'Alteração de área do terreno',
			'Alteração da classificação arquitetônica',
			'Alteração da data de construção',
			'Alteração de endereço do imóvel',
			'Cadastramento predial',
			'Cancelamento de unidade imobiliária',
			'Desmembramento de unidade imobiliária',
			'Impugnação do lançamento',
			'Inscrição e alteração do sujeito passivo',
			'Remembramento de unidades imobiliárias',
			'Outros'
		]

	}

	checkDescriptionRequest() {
		const formVariants = this.form.get('formVariants');
		const description = getDescription(this.subject);
		if (description !== null) {
			formVariants.get('SOL_Descricao').patchValue(description);
			this.haveDescription = true;
		} else {
			formVariants.get('SOL_Descricao').patchValue('');
			formVariants.get('SOL_Descricao').setValidators(Validators.required);
			this.haveDescription = false;
		}
	}

	getAddressApplicantData(event) {
		if (this.form.get('applicantData')) {
			this.form.get('applicantData').patchValue({
				SOLIP_RequerenteMunicipio: event.localidade,
				SOLIP_RequerenteBairro: event.bairro,
				SOLIP_RequerenteLogradouro: event.logradouro,
				SOLIP_RequerenteUF: event.uf,
				SOLIP_RequerenteNumero: event.numero,
				SOLIP_RequerenteComplemento: event.complemento,
				SOLIP_RequerenteCEP: event.CEP
			});
			this.form.updateValueAndValidity();
		}
	}
	getAddressImmunityRequest(event) {
		if (this.form.get('immunityRequest')) {
			this.form.get('immunityRequest').patchValue({
				SOLIP_RequerenteMunicipio: event.localidade,
				SOLIP_RequerenteBairro: event.bairro,
				SOLIP_RequerenteLogradouro: event.logradouro,
				SOLIP_RequerenteUF: event.uf,
				SOLIP_RequerenteNumero: event.numero,
				SOLIP_RequerenteComplemento: event.complemento,
				SOLIP_RequerenteCEP: event.CEP
			});
			this.form.updateValueAndValidity();
		}
	}

	existApplicantControl(formControlName: string) {
		return this.form.get('applicantData').get(formControlName) !== null;
	}

	checkDiseaseApplicant(check: boolean) {
		const applicant = this.form.get('applicantData');
		if (check) {
			this.form.get('diseaseData').patchValue({
				SOLIP_DoenteNome: applicant.get('SOLIP_RequerenteNomeRazaoSocial').value,
				SOLIP_DoenteCpf: applicant.get('SOLIP_RequerenteCpfCnpj').value,
				SOLIP_DoenteRG: applicant.get('SOLIP_RequerenteRG').value
			});
			this.form.get('diseaseData').get('SOLIP_DoenteVinculo').disable();
		} else {
			this.form.get('diseaseData').patchValue({
				SOLIP_DoenteNome: '',
				SOLIP_DoenteCpf: '',
				SOLIP_DoenteRG: ''
			});
			this.form.get('diseaseData').get('SOLIP_DoenteVinculo').enable();
		}
		this.form.updateValueAndValidity();
	}

	resetFormGroupsBySubject() {
		this.form = this.fb.group({});
		this.renderForm();
		if (this.enderecoFormComponent) {
			this.enderecoFormComponent.reset();
		}
		this.formChanges();
		this.form.updateValueAndValidity();
	}

	getEnumDescription(value: string): number {
		return descriptionRadioButtonEnum[value];
	}

	private get immunityData() {
		return this.form.get('immunityData') as FormArray;
	}

	public getDataInscricao() {

		const tipoImovel = this.form.get('propertyData').get('SOLIP_TipoImovel')?.value.toLowerCase();
		const inscricao = this.form.get('propertyData').get('SOLIP_InscricaoImovel').value;
		const eraseFields = () => {
			this.form.get('propertyData').patchValue({
				SOLIP_Logradouro: '',
				SOLIP_Complemento: '',
				SOLIP_Bairro: '',
				SOLIP_CEP: '',
				SOLIP_Numero: '',
			});
			this.form.updateValueAndValidity();
		}

		this.fiscoService.getDataInscricao(inscricao, tipoImovel).subscribe({
			next: (response: any) => {
				const data = response.data;
				if (response.success) {
					this.form.get('propertyData').patchValue({
						SOLIP_Logradouro: data.imovel_logradouro,
						SOLIP_Complemento: data.imovel_complemento,
						SOLIP_Bairro: data.imovel_bairro,
						SOLIP_CEP: data.imovel_cep,
						SOLIP_Numero: data.imovel_numero,
					});
					this.form.updateValueAndValidity();
				}
				else {
					this.alertError("Código de imóvel inválido!");
					eraseFields();
				}
			},
			error: () => {
				this.alertError("Código de imóvel inválido!");
				eraseFields();
			},
		})

	}
}
