import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'view-processo-skeleton-screen',
    templateUrl: './view-processo-skeleton-screen.component.html',
})
export class ViewProcessoSkeletonScreenComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
