import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GerinusService } from '../lib/gerinus.service';

@Injectable()
export class PersonService extends GerinusService {
    constructor(public http: HttpClient) {
        super(http);
        this.entityName = 'pessoa';
        this.idField = 'PE_CodigoPessoa';
        this.model = 'PessoaModel';
        this.orderBy = 'PE_Nome';
    }
}
