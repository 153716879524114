import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { GerinusBD } from 'src/app/app-core/lib/gerinus-bd.component';
import { GlobalService } from 'src/app/app-core/lib/global.service';
import { ProcessService } from 'src/app/app-core/services/process.service';
import { UserService } from 'src/app/app-core/services/user.service';
import { ICancellationEvaluationSchema, IProccess } from 'src/app/interfaces/proccess.interface';
import Swal from 'sweetalert2';

@Component({
  selector: 'cancellation-info',
  templateUrl: './cancellation-info.component.html',
  providers: [ProcessService, MessageService, UserService]
})
export class CancellationInfoComponent extends GerinusBD implements OnInit {

  @Input() process: IProccess
  @Input() isCancellationRequestSectionVisible?: boolean

  @Output() processCancelled?: EventEmitter<number> = new EventEmitter<number>();

  constructor(
    public global: GlobalService, 
    private processService: ProcessService, 
    private messageService: MessageService,
    private userService: UserService
  ) {
    super(global, processService);
    this.afterCreate();
    this.add();
  }

  public defaults() {
    return {
      cancellationInfo: {
        evaluationDescription: ''
      }
    }
  }

  public cancellationRequesterName: string;
  public reviewerName: string;


  ngOnInit(): void {
    if (this.process.PSA_Solicitante) {
      this.getCancellationRequesterName();
    }

    if (this.process.PSA_AvaliadaPor) {
      this.getReviewerName();
    }
  }

  public getCancellationRequesterName(): void {
    this.userService.getEntity(this.process.PSA_Solicitante).subscribe({
      next: (response: any) => {
        this.cancellationRequesterName = response.entity.pessoa.PE_Nome;
      }
    })
  }

  public getReviewerName(): void {
    this.userService.getEntity(this.process.PSA_AvaliadaPor).subscribe({
      next: (response: any) => {
        this.reviewerName = response.entity.pessoa.PE_Nome;
      }
    })
  }

  handleConfirmCancellation() {
    const confirmProcessCancellationData: ICancellationEvaluationSchema = {
      userId: this.global.user().USR_CodigoUsuario,
      history: `${this.global.user().PE_Nome} confirmou a anulação do processo.`,
      evaluationDescription: this.entity.cancellationInfo.evaluationDescription
    }

    Swal.fire({
      title: 'Confirmar anulação do processo?',
      text: 'Após realizar esta ação, a anulação do processo não poderá ser desfeita.',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#417bff',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        this.processService.confirmCancellationProcess(this.process.PRC_CodigoProcesso, confirmProcessCancellationData).subscribe({
          next: () => {
            this.processCancelled.emit(this.process.PRC_CodigoProcesso);
            this.messageService.add({
              severity: 'success',
              summary: 'Sucesso',
              detail: 'O processo foi anulado'
            });
          }
        })
      } else {
        Swal.close()
      }
    });
  }

  handleRefuseCancellation() {
    const refuseProcessCancellationData: ICancellationEvaluationSchema = {
      userId: this.global.user().USR_CodigoUsuario,
      history: `${this.global.user().PE_Nome} recusou a anulação do processo.`,
      evaluationDescription: this.entity.cancellationInfo.evaluationDescription
    }

    Swal.fire({
      title: 'Deseja recusar a anulação?',
      text: 'Após realizar esta ação, o processo irá prosseguir normalmente.',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#417bff',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        this.processService.refuseCancellationProcess(this.process.PRC_CodigoProcesso, refuseProcessCancellationData).subscribe({
          next: () => {
            this.processCancelled.emit(this.process.PRC_CodigoProcesso);
            this.messageService.add({
              severity: 'success',
              summary: 'Sucesso',
              detail: 'A solicitação de anulação foi recusada'
            });
          }
        })
      } else {
        Swal.close()
      }
    });
  }

  public get isCancellationInfoVisibile(): boolean {
    return this.process.Status === 7 || 
      (this.process.PSA_AvaliadaPor && 
        this.process.PSA_Aceita === false && 
        this.global.user().PE_CodigoPessoa === this.process.PRC_CodigoResponsavel) ||
        this.global.user().USR_CodigoUsuario === this.process.PSA_AvaliadaPor
  }

}
