import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ComponentsModule } from 'src/app/components/components.module';

import { LoginService } from 'src/app/app-core/services/login.service';
import { GlobalService } from 'src/app/app-core/lib/global.service';
import { ProcessService } from 'src/app/app-core/services/process.service';
import { DocumentService } from 'src/app/app-core/services/document.service';
import { RequestService } from 'src/app/app-core/services/request.service';

import AOS from 'aos';
import 'aos/dist/aos.css';

@Component({
    selector: 'app-login',
    templateUrl: './landing-page.component.html',
    styleUrls: ['./landing-page.component.scss'],
    providers: [LoginService, ComponentsModule, ProcessService, DocumentService, RequestService],
})
export class LandingPageComponent implements OnInit, AfterViewInit {
    public processList: any[];
    public valueInputProcess: string;
    public authKeyDocument: string;
    public loadScreen = true;
    public messages;
    public searchValue: string;

    constructor(
        public service: LoginService,
        public route: ActivatedRoute,
        public global: GlobalService,
        public documentService: DocumentService,
        public processService: ProcessService,
        public requestService: RequestService,
        public activatedRoute: ActivatedRoute,
    ) {
        const decodedPath = decodeURIComponent(window.location.href.split('redirect=')[1]);
        const queryString = decodedPath.split('?')[1];
        const queryParams = this.global.decodeQueryString(queryString);

        if (queryParams.code) {
            this.loadScreen = false;
            this.global.startLoading();
            const code = queryParams.code;
            this.service.authentication(true, code);
            this.global.stopLoading();
        }

        this.activatedRoute.queryParams.subscribe((params) => {
            if (params.logout == 'true') {
                this.service.logout();
            }
        });

        this.messages = this.getItemsFAQ();
    }

    ngOnInit() {
        const title = document.querySelector('title');
        title.text = 'Bem-vindo ao Protocolize';
        this.route.params.subscribe((params) => {
            if (params.type && params.token) {
                const payload = {
                    type: params.type,
                    accessToken: params.token,
                };

                this.service.authentication(payload, 'isGovBr');
            }
        });

        AOS.init();
    }

    ngAfterViewInit() {
        const items = document.querySelectorAll('.accordion button');

        function toggleAccordion() {
            const itemToggle = this.getAttribute('aria-expanded');

            for (let i = 0; i < items.length; i++) {
                items[i].setAttribute('aria-expanded', 'false');
            }

            if (itemToggle == 'false') {
                this.setAttribute('aria-expanded', 'true');
            }
        }

        items.forEach((item) => item.addEventListener('click', toggleAccordion));

        const menuLinks = document.querySelectorAll('ul li a');

        // Função para adicionar ou remover a classe 'active' no item do menu e ficar com a cor primária
        function setActiveLink(link) {
            menuLinks.forEach((menuLink) => {
                if (menuLink === link) {
                    menuLink.classList.add('active');
                } else {
                    menuLink.classList.remove('active');
                }
            });
        }

        // Função para verificar a posição da seção e atualizar o item ativo no menu
        function checkSectionPosition() {
            const sections = document.querySelectorAll('section');
            const currentPosition = window.pageYOffset;
            const offset = 100;

            sections.forEach((section) => {
                const sectionTop = section.offsetTop;
                const sectionHeight = section.offsetHeight;

                if (currentPosition >= sectionTop - offset && currentPosition < sectionTop + sectionHeight - offset) {
                    const link = document.querySelector(`a[data-target="${section.id}"]`);
                    setActiveLink(link);
                }
            });
        }

        // Verifica a posição da seção ao carregar a página e durante o evento de rolagem
        window.addEventListener('DOMContentLoaded', checkSectionPosition);
        window.addEventListener('scroll', checkSectionPosition);
    }

    public search() {
        this.global.router.navigate(['/search-process'], { queryParams: { searchValue: this.searchValue } });
    }

    public getDocumentbyAuthKey() {
        if (this.authKeyDocument.length === 12) {
            this.global.startLoading('Validando...', true);
            this.documentService.getVericityOfDocument(this.authKeyDocument).subscribe({
                next: (response: any) => {
                    this.global.stopLoading();
                    this.global.swal.fire(response.swal).then((result) => {
                        if (result.isConfirmed && response.ok) {
                            if (response.type === 'documento') {
                                this.global.swal.fire({
                                    title: response.entity.DOC_Assunto,
                                    html: response.entity.DOC_Conteudo,
                                });
                            } else if (response.type === 'solicitacao') {
                                this.requestService.generateRequestPDF(response.entity);
                            } else if (response.type === 'processo') {
                                this.processService.generateProcessPDF(response.entity);
                            }
                        }
                    });
                },
            });
        } else {
            this.global.swal.fire({
                icon: 'error',
                title: 'Chave inválida!',
                text: 'Informe uma chave válida.',
            });
        }
    }

    public gotoProcess(processNumber) {
        this.global.router.navigate(['/free/processo/' + processNumber]);
    }

    public navClick(section: string) {
        document.getElementById(section)?.scrollIntoView({ behavior: 'smooth' });
    }

    public getItemsFAQ(): { title: string, text: string }[] {
        return [
            {
                title: "Como entrar no sistema?",
                text: `Para acessar o Protocolize, é necessário possuir uma conta <b>gov.br</b>. Se você ainda não possui uma, pode criar facilmente seguindo <a href="https://www.gov.br/pt-br/servicos/criar-sua-conta-gov.br">este link.</a>A conta gov.br concede acesso a uma variedade de serviços digitais oferecidos pelo governo, incluindo o Protocolize. Utilizaremos essa conta para identificá-lo no sistema.`
            },
            {
                title: "Quem pode utilizar o Protocolize?",
                text: `Qualquer cidadão que necessite de um dos serviços oferecidos pelos órgãos do município pode enviar uma solicitação, a qual será avaliada pelo órgão responsável pelo serviço.`
            },
            {
                title: "O que é um processo?",
                text: `Um processo geralmente consiste em uma solicitação formalizada por um cidadão ou entidade para obter um determinado serviço, licença, autorização ou resolver uma questão administrativa relacionada à jurisdição municipal.`
            },
            {
                title: "Como validar meu processo ou solicitação?",
                text: `No PDF que contém o resumo da solicitação/processo, ao final terá um código de verificação. Esse código é único e irá servir para fins de validação. Para verificar se o documento é válido, você deve acessar <a (click)="navClick('document-validation')" data-target="document-validation" class="text-primary point">este link</a> e digitar o código de verificação. Também é possível validar através do QR code que também está contido nos documentos.`
            },
            {
                title: "Minha solicitação precisa de correção, e agora?",
                text: `Se sua solicitação foi avaliada pela equipe de triagem e a mesma mudou o status para "Correção de Pendências", você deve avaliar as informações da avaliação e realizar as devidas correções. Na maioria dos casos, você deve enviar um novo documento, para isso, anule o documento anterior clicando no ícone "x" e selecione um novo arquivo corrigido.`
            }
        ];
    }
}
