import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GerinusService } from '../lib/gerinus.service';
import jsPDF from 'jspdf';
import autoTable, { UserOptions } from 'jspdf-autotable';
@Injectable()
export class SubjectService extends GerinusService {

    public specificSubjectsIPTU = [
        'IPTU - Isenção Viúvas',
        'IPTU - Isenção sem fins lucrativos',
        'IPTU - Isenção cedido gratuitamente',
        'IPTU - Isenção Servidor Público',
        'IPTU - Isenção Locado no Munícipio'
    ];

    constructor(public http: HttpClient) {
        super(http);
        this.entityName = 'assunto';
        this.idField = 'AS_CodigoAssunto';
        this.model = 'AssuntoModel';
    }

    listByOrgaoAndType(id: number, type: string) {
        return this.listAll(`"AS_CodigoOrgao" = ${id} AND "AS_TipoProcesso" = '${type}'`, 0);
    }

    listByDepAndType(id: number, type: string) {
        return this.listAll(`"AS_CodigoDepartamento" = ${id} AND "AS_TipoProcesso" = '${type}'`, 0);
    }

    public generateServiceListPDF(title: string, condition = null) {
        const doc = new jsPDF('p', 'mm', 'a4');
        doc.setFont('Inter-SemiBold');
        doc.text(title, doc.internal.pageSize.width / 2 || doc.internal.pageSize.getWidth() / 2, 15, { align: 'center' });
        this.listAll(condition, 0).subscribe({
            next: (response: any) => {

                if (response.data && response.data.length > 0) {

                    const tableData = [];

                    for (let item of response.data) {
                        tableData.push([item.AS_Descricao, item.OR_Descricao, item.DEP_Descricao, item.AS_PrazoProcesso, item.AS_PrazoCorrecao]);
                    }

                    const tableConfig: UserOptions = {
                        startY: 20,
                        margin: { top: 20 },
                        styles: {
                            fontSize: 10,
                        },
                        headStyles: {
                            fontSize: 8,
                        },
                        bodyStyles: {
                            fontSize: 8,
                        },
                    };

                    autoTable(doc, {
                        head: [['NOME', 'ÓRGÃO RESPONSÁVEL', 'DEPARTAMENTO RESPONSÁVEL', 'PRAZO DO PROCESSO', 'PRAZO DE CORREÇÃO'],],
                        body: tableData,
                        ...tableConfig,
                    });

                    doc.save("Lista de Serviços.pdf");

                }
            }
        })
    }
}
