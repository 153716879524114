import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GerinusService } from '../lib/gerinus.service';

@Injectable()
export class RequestHistoryService extends GerinusService {
    constructor(public http: HttpClient) {
        super(http);
        this.entityName = 'solicitacao_historico';
        this.idField = 'SOLH_CodigoHistorico';
        this.model = 'SolicitacaoHistoricoModel';
    }
}
