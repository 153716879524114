import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { TesteComponent } from './modules/teste/teste.component';
import { DashboardComponent } from '../pages/dashboard/dashboard.component';
import { UserProfileComponent } from '../pages/user-profile/user-profile.component';
import { HelpGuideComponent } from '../pages/help-guide/help-guide.component';
import { AuthGuard } from '../auth.guard';

const routes: Routes = [
    {
        path: 'inicio',
        component: DashboardComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'gerencial',
        loadChildren: () => import('./modules/gerencial/gerencial.module').then((m) => m.GerencialModule),
    },
    {
        path: 'requests',
        loadChildren: () => import('./modules/requests/requests.module').then((m) => m.RequestsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'process',
        loadChildren: () => import('./modules/proccess/process.module').then((m) => m.ProcessModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'documents',
        loadChildren: () => import('./modules/documents/documents.module').then((m) => m.DocumentsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'essentials',
        loadChildren: () => import('./modules/essentials/essentials.module').then((m) => m.EssentialsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'internal-process',
        loadChildren: () => import('./modules/internal-processes/internal-processes.module').then((m) => m.InternalProcessesModule),
    },
    {
        path: 'teste',
        component: TesteComponent,
    },
    {
        path: 'profile',
        component: UserProfileComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'help',
        component: HelpGuideComponent,
        canActivate: [AuthGuard]
    }
];

@NgModule({
    imports: [CommonModule, RouterModule.forChild(routes)],
    exports: [],
})
export class AppCoreRoutingModule { }
