import { Component } from '@angular/core';
import { GerinusBD } from '../../lib/gerinus-bd.component';
import { UserService } from '../../services/user.service';
import { GroupService } from '../../services/group.service';
import { GlobalService } from '../../lib/global.service';

@Component({
    templateUrl: 'teste.component.html',
    styleUrls: [],
    providers: [UserService, GroupService],
})
export class TesteComponent extends GerinusBD {
    constructor(
        public global: GlobalService,
        public service: UserService,
    ) {
        super(global, service);
    }
}
