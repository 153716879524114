import { Component, OnInit } from '@angular/core';

import { GerinusBD } from 'src/app/app-core/lib/gerinus-bd.component';

import { GlobalService } from 'src/app/app-core/lib/global.service';
import { GerinusService } from 'src/app/app-core/lib/gerinus.service';
import { UserService } from 'src/app/app-core/services/user.service';
import { UploadService } from 'src/app/app-core/services/upload.service';

import Swal from 'sweetalert2';

@Component({
    selector: 'app-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss'],
    providers: [GlobalService, GerinusService, UserService, UploadService],
})
export class UserProfileComponent extends GerinusBD implements OnInit {
    constructor(
        public service: GerinusService,
        public global: GlobalService,
        public userService: UserService,
        public uploadService: UploadService,
    ) {
        super(global, userService);
        this.add();
    }

    public swal = Swal;
    public modalName: string = 'image-upload';
    public mimeTypes: string[] = ['image/jpeg', 'image/png'];
    public formattedGroupName: string = this.userService.formatGroupName();
    public groupToChange: string = this.userService.isCidadao()
        ? this.userService.formatGroupName(this.global.user().USG_CodigoGrupoOriginal)
        : 'Cidadão';

    ngOnInit() {
        document.querySelector('title').text = 'Meu Perfil';
    }

    public defaults() {
        return {
            USR_CodigoUsuario: this.global.user().USR_CodigoUsuario,
            usuario: {
                USR_Foto: '',
            },
        };
    }

    public getUploadedFile(event: any) {
        this.entity.usuario.USR_Foto = event.file.url;

        this.save();
    }

    public onDeleteFileUploaded(event: any) {
        if (this.entity.file && this.entity.file.length > event.index) {
            this.entity.file.splice(event.index, 1);
        }
    }

    public async save() {
        this.userService.save(this.entity).subscribe({
            next: async () => {
                let userSaved = this.global.user();
                userSaved.USR_Foto = await this.getImageUrl(this.entity.usuario.USR_Foto);

                localStorage.setItem('user', JSON.stringify(userSaved));
                this.global.closeModalInfo(this.modalName);
                this.global.swal.fire({
                    title: 'Foto atualizada com sucesso!',
                    icon: 'success',
                })
            }
        });
    }

    handleChangeGroup() {
        this.userService.handleChangeInternalUserGroup(this.global.user().USR_LembrarEscolha);
    }

    async getImageUrl(imageName: string): Promise<string> {
        return new Promise((resolve, reject) => {
            this.uploadService.download(imageName).subscribe({
                next: (response: any) => {
                    resolve(response.url);
                },
                error: (error: any) => {
                    reject(error);
                }
            });
        });
    }

    toggleChangeToSecondaryUserGroup() {
        const userId = this.global.user().USR_CodigoUsuario;

        const userRequest = {
            originalGroup: this.global.user().USG_CodigoGrupoOriginal,
            secondaryGroup: this.global.user().USG_CodigoGrupoSecundario,
        };

        this.userService.toggleToSecondaryUserGroup(userId, userRequest).subscribe({
            next: (response: any) => {
                Swal.fire({
                    title: 'Grupo de usuário Alterado',
                    text: `Seu acesso agora é como ${this.userService.formatGroupName(response.data.USG_CodigoGrupo)}`,
                    icon: 'success',
                    showCancelButton: true,
                    cancelButtonColor: '#417bff',
                    cancelButtonText: 'OK',
                    showConfirmButton: false,
                }).then(() => {
                    const userResponse = response.data;
                    
                    const userData = this.global.user();

                    userData.USG_CodigoGrupo = userResponse.USG_CodigoGrupo;
                    userData.USG_CodigoGrupoOriginal = userResponse.USG_CodigoGrupoOriginal;
                    userData.USR_Administrador = userResponse.USR_Administrador;
                    userData.GRP_CodigoGrupo = userResponse.GRP_CodigoGrupo;
                    userData.GRP_Descricao = userResponse.GRP_Descricao;
                    userData.USR_LembrarEscolha = userResponse.USR_LembrarEscolha;

                    localStorage.setItem('user', JSON.stringify(userData));
                    window.location.reload();
                });
            },
        });
    }


}
