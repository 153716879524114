import { Component } from '@angular/core';
import { UserGroupEnum } from 'src/app/Enums/UserGroupEnum';
import { UserService } from 'src/app/app-core/services/user.service';

@Component({
  selector: 'app-help-guide',
  templateUrl: './help-guide.component.html',
  styleUrls: ['./help-guide.component.scss'],
  providers: [UserService]
})
export class HelpGuideComponent {

  public showHeader = true;
  public messages;

  private user = this.userService.getUser();

  constructor(private readonly userService: UserService) {
    this.messages = this.getItemsByUserGroup();
  }

  public getItemsByUserGroup(): { title: string, text: string }[] {
    switch (this.user.GRP_Descricao) {
      case UserGroupEnum.CIDADAO:
        return [
          {
            title: "Como acesso as minhas Solicitações?",
            text: `Para acessar suas solicitações enviadas, clique em "Solicitações" na aba de Menu principal. Lá, você encontrará os detalhes da sua solicitação, como número, assunto, dados e a fase atual. Para visualizar as ações, clique em "<i class="ph ph-eye"></i>" no canto direito da tela. Para obter mais informações sobre prazos e atividades, clique no ícone "<i class="ph-thin ph-plus"></i>".`
          },
          {
            title: "Como faço para cadastrar uma Solicitação?",
            text: `Para cadastrar uma solicitação acesse a aba "Solicitações" no Menu Principal, e clique na opção "Nova Solicitação". Logo após, preencha os dados da solicitação, como o orgão que irá ser encaminhado, o assunto e a descrição da solicitação, clique em complementares (opctional). Os arquivos anexados não podem exceder 40MB e deve ser em formato PDF. Logo após concorde com o termo de veracidade de informações em resumo de solicitações e clique em enviar. Após isso seu comprovante irá ser emitido e irá para o processo de triagem.`
          },
          {
            title: "Como cadastrar uma Solicitação em nome de outra pessoa?",
            text: `Para cadastrar uma solicitação em nome de outra pessoa, marque a caixa de seleção “Solicitar no nome de outra pessoa” na seção “Dados do Solicitante” e aceite o termo de responsabilidade. Em seguida, escolha entre pessoa jurídica ou pessoa física no campo “Tipo de pessoa” e anexe a procuração. Lembre-se de que a procuração deve ser autenticada em cartório para ser válida. Você também deve fornecer suas credenciais, incluindo CPF, Nome e E-mail. Depois disso, basta clicar em ”Prosseguir” para continuar com a solicitação.`
          },
          {
            title: "Como visualizo o meu Perfil?",
            text: `Para visualizar o perfil do usuário, clique no ícone “<i class="ph-thin ph-user"></i>“  localizado na parte superior, ao lado das notificações. Nesta seção, você encontrará o nome do usuário e seu tipo. A aba “Perfil' permite que você acesse suas informações, como Nome, CPF e E-mail, juntamente com seu status atual. Também é exibido o botão “Sair” caso deseje fazer logout.`
          },
          {
            title: "Como acesso a Área de Notificações?",
            text: `Para acessar suas solicitações enviadas, clique em “Solicitações” na aba de Menu Principal. Lá, você encontrará os detalhes da sua solicitação, como número, assunto, dados e a fase atual. Para visualizar as ações, clique no ícone “<i class="ph ph-eye"></i>” no canto direito da tela. Para obter mais informações sobre prazos e atividades, clique no ícone “<i class="ph ph-plus"></i>” no canto esquerdo.`
          },
          {
            title: "O que ocorre se minha Solicitação for encaminhada para correção pela Triagem?",
            text: `Após enviar a solicitação, ela passará pelo processo de triagem, onde será avaliada e validada. Se alguma informação ou documentação estiver incorreta, a equipe de triagem encaminhará a solicitação de volta ao usuário para correção. Para corrigir as pendências, vá na aba “Solicitações” e clique no ícone “<i class="ph ph-pencil"></i>” na solicitação  referente à “correção de pendências” para editá-la; logo após, clique no ícone “<i class="ph ph-x"></i>“ no documento que precisa ser corrigido para anular o arquivo antigo e no ícone “<i class="ph ph-download-simple"></i>“ para inserir um novo documento. Após as correções, clique no botão “Enviar correção”. Se tudo estiver correto, a solicitação será aprovada."`
          },
          {
            title: "Quais são as fases do Processo de Solicitação?",
            text: `O processo de solicitação é dividido em três fases: a fase "Em andamento", na qual a solicitação está sendo analisada pela triagem; a fase de "Correção", quando informações ou documentações estão incorretas e a triagem solicita ao usuário que faça as correções necessárias; e a fase "Aceita", na qual a solicitação é aprovada.`
          }
        ];
      case UserGroupEnum.TRIAGEM:
        return [
          {
            title: "Como visualizo o meu Perfil?",
            text: `Para visualizar o perfil do usuário, clique no ícone “<i class="ph ph-user"></i>“  localizado na parte superior, ao lado das notificações. Nesta seção, você encontrará o nome do usuário e seu tipo. A aba “Perfil' permite que você acesse suas informações, como Nome, CPF e E-mail, juntamente com seu status atual e dados profissionais. Também é exibido o botão “Sair” caso deseje fazer logout.`
          },
          {
            title: "Como escolher o tipo de usuário?",
            text: `Ao fazer login no Protocolize, você será apresentado com uma mensagem de "Escolher tipo de usuário", onde terá a opção de continuar como usuário de triagem ou mudar para o perfil de usuário cidadão. Se desejar prosseguir como usuário de triagem, clique em "Continuar como Triagem"; caso prefira mudar para o usuário cidadão, clique em "Trocar usuário para cidadão". Se desejar que sua escolha seja lembrada para futuros acessos, marque a caixa "Lembrar minha escolha". `
          },
          {
            title: "Como acesso a Área de Notificações?",
            text: `Para acessar as solicitações que estão em análise, clique em “Triagem” na aba de Menu Principal. Lá, você encontrará os detalhes da solicitação que estão em processo de triagem, como número, assunto, dados e a fase atual. Para visualizar e validar a solicitação, clique em ações no ícone “<i class="ph ph-eye"></i>” no canto direito da tela. Confira se as informações e as documentações estão corretas, se sim, aprove e aperte no botão “Aprovar e Tramitar” e escolha o departamento na qual a solicitação irá ser direcionada, se caso estiver com alguma pendência, clique no botão “Correção” e descreva as informações a serem corrigidas para devolver ao usuário. Para obter mais informações sobre prazos e atividades, clique no ícone “<i class="ph-thin ph-plus"></i>” no canto esquerdo na aba incial de “Triagem”.`
          },
          {
            title: "Como faço para cadastrar uma Solicitação?",
            text: `Para cadastrar uma solicitação acesse a aba “Triagem” no Menu Principal, e clique a opção “Nova Solicitação”.  Logo após, preencha os dados da solicitação, como o orgão que irá ser encaminhado, o assunto e  a descrição da solicitação, clique em prosseguir e anexe os documentos necessários que justifiquem a solicitação, como requerimento e os documentos complementares (opcional). Os Arquivos anexados não podem exceder 40MB e deve ser em formato PDF. Logo após concorde com o termo de veracidade de informações em resumo de solicitações  e clique em enviar. Após isso seu comprovante irá ser emitido. No ambiente de triagem o usuário “Triagem” só irá ter a opção de “Aprovar e Tramitar”, após isso direcione a solicitação ao departamento responsável. `
          },
          {
            title: "Como cadastrar uma Solicitação em nome de outra pessoa?",
            text: "Para cadastrar uma solicitação em nome de outra pessoa, marque a caixa de seleção “Solicitar no nome de outra pessoa” na seção “Dados do Solicitante”. Em seguida, escolha entre pessoa jurídica ou pessoa física no campo “Tipo de pessoa” e anexe a procuração. Lembre-se de que a procuração deve ser autenticada em cartório para ser válida. Você também deve fornecer suas credenciais, incluindo CPF, Nome e E-mail. Depois disso, basta clicar em ”Prosseguir” para continuar com a solicitação. "
          },
          {
            title: "Como acesso as Solicitações que estão em análise?",
            text: `Para acessar as solicitações que estão em análise, clique em “Triagem” na aba de Menu Principal. Lá, você encontrará os detalhes da solicitação que estão em processo de triagem, como número, assunto, dados e a fase atual. Para visualizar e validar a solicitação, clique em ações no ícone “<i class="ph ph-eye"></i> ” no canto direito da tela. Confira se as informações e as documentações estão corretas, se sim, aprove e aperte no botão “Aprovar e Tramitar” e escolha o departamento na qual a solicitação irá ser direcionada, se caso estiver com alguma pendência, clique no botão “Correção” e descreva as informações a serem corrigidas para devolver ao usuário. Para obter mais informações sobre prazos e atividades, clique no ícone “<i class="ph ph-pencil"></i>” no canto esquerdo na aba incial de “Triagem”.`
          },
          {
            title: "Como funciona a validação da Triagem?",
            text: `Após o usuário enviar a solicitação, esta passará pelo processo de triagem, onde será avaliada e validada. Se alguma informação ou documentação estiver incorreta, a equipe de triagem encaminhará a solicitação de volta ao usuário para correção. Para visualizar a solicitação e realizar a validação, clique no ícone “<i class="ph ph-eye"></i>“. Lá, você encontrará os dados do solicitante, o órgão a ser encaminhado, a descrição da solicitação e os documentos. Se tudo estiver correto, clique no botão “Aprovar e Tramitar” e selecione o departamento para o qual a solicitação será direcionada. Caso haja alguma informação ou documento incorreto, clique em “Correção” e informe as informações que precisam ser corrigidas. Após isso, a solicitação será devolvida ao usuário para que ele proceda com as correções necessárias.`
          },
          {
            title: "Como realizo a consulta de Processos?",
            text: `Para realizar a consulta de processos acesse “Consulta de Processos” na aba de Menu, lá irá conter diversas informações como  N° da solicitação, Número do processo (NUP), departamento, assunto, data e hora de recebimento e a situação do processo. Para ver os detalhes do processo clique em “<i class="ph ph-eye"></i>“  lá ira conter o responsável do processo, dados do solicitante e da solicitação, descrição, histórico e anexos. para fazer o download do documento enviado clique no Icone “<i class="ph ph-download-simple"></i>“, para gerar o pdf do processo clique em “Gerar PDF” na parte superior da tela.  para mais informações clique em “<i class="ph-thin ph-plus"></i>“ na página inicial de processos e irá encontrar os dados, atividade e o prazo.`
          },
          {
            title: "Como visualizo as Solicitações que estão aguardando correção?",
            text: `Para cadastrar uma solicitação acesse a aba “Triagem” no Menu Principal, e clique a opção “Nova Solicitação”.  Logo após, preencha os dados da solicitação,   como o orgão que irá ser encaminhado, o assunto e  a descrição da solicitação, clique em prosseguir e anexe os documentos necessários que justifiquem a solicitação, como requerimento e os documentos complementares (opcional). Os Arquivos anexados não podem exceder 40MB e deve ser em formato PDF. Logo após concorde com o termo de veracidade de informações em resumo de solicitações  e clique em enviar. Após isso seu comprovante irá ser emitido. No ambiente de triagem o usuário “Triagem” só irá ter a opção de “Aprovar e Tramitar”, após isso direcione a solicitação ao departamento responsável.`
          },
          {
            title: "Qual o prazo de resposta para uma Solicitação?",
            text: `O prazo de resposta para uma solicitação varia conforme o tipo da mesma. Para consultar o prazo referente à solicitação desejada, basta clicar no ícone "<i class="ph-thin ph-plus"></i>" na seção "Solicitações em análise". Lá, será exibido o prazo da solicitação. `
          }
        ];
      case UserGroupEnum.ADMINISTRADOR:
        return [
          {
            title: "Como visualizo o meu Perfil?",
            text: `Para visualizar o perfil do usuário, clique no ícone “<i class="ph ph-user"></i>“  localizado na parte superior, ao lado das notificações. Nesta seção, você encontrará o nome do usuário e seu tipo. A aba “Perfil' permite que você acesse suas informações, como Nome, CPF e E-mail, juntamente com seu status atual e dados profissionais. Também é exibido o botão “Sair” caso deseje fazer logout.`
          },
          {
            title: "Como acesso as Solicitações que estão em Análise?",
            text: `Para acessar as solicitações que estão em análise, clique em “Triagem” na aba de Menu Principal. Lá, você encontrará os detalhes da solicitação que estão em processo de triagem, como número, assunto, dados e a fase atual. Para visualizar e validar a solicitação, clique em ações no ícone “<i class="ph ph-eye"></i> ” no canto direito da tela. Confira se as informações e as documentações estão corretas, se sim, aprove e aperte no botão “Aprovar e Tramitar” e escolha o departamento na qual a solicitação irá ser direcionada, se caso estiver com alguma pendência, clique no botão “Correção” e descreva as informações a serem corrigidas para devolver ao usuário. Para obter mais informações sobre prazos e atividades, clique no ícone “<i class="ph ph-pencil"></i>” no canto esquerdo na aba incial de “Triagem”.`
          },
          {
            title: "Como faço para cadastrar uma Solicitação?",
            text: `Para cadastrar uma solicitação acesse a aba “Solicitações” no Menu Principal, e clique a opção “Nova Solicitação”.  Logo após, preencha os dados da solicitação, como o orgão que irá ser encaminhado, o assunto e  a descrição da solicitação, clique em prosseguir e anexe os documentos necessários que justifiquem a solicitação, como requerimento e os documentos complementares (opcional). Os Arquivos anexados não podem exceder 25MB e deve ser em formato PDF. Logo após concorde com o termo de veracidade de informações em resumo de solicitações  e clique em enviar. Após isso seu comprovante irá ser emitido e irá para o processo de triagem.`
          },
          {
            title: "Como cadastrar uma Solicitação em nome de outra pessoa?",
            text: `Para cadastrar uma solicitação em nome de outra pessoa, marque a caixa de seleção “Solicitar no nome de outra pessoa” na seção “Dados do Solicitante” e aceite o termo de responsabilidade. Em seguida, escolha entre pessoa jurídica ou pessoa física no campo “Tipo de pessoa” e anexe a procuração. Lembre-se de que a procuração deve ser autenticada em cartório para ser válida. Você também deve fornecer suas credenciais, incluindo CPF, Nome e E-mail. Depois disso, basta clicar em ”Prosseguir” para continuar com a solicitação.`
          },
          {
            title: "Como escolho o tipo de usuário?",
            text: `Ao fazer login no Protocolize, você será apresentado com uma mensagem de "Escolher tipo de usuário", onde terá a opção de continuar como usuário de administrador ou mudar para o perfil de usuário cidadão. Se desejar prosseguir como usuário de administrador, clique em "Continuar como Administrador"; caso prefira mudar para o usuário cidadão, clique em "Trocar usuário para cidadão". Se desejar que sua escolha seja lembrada para futuros acessos, marque a caixa "Lembrar minha escolha".`
          },
          {
            title: "Como acesso a Gestão de Processos?",
            text: `Para visualizar a gestão de processos, clique em “Gestão de Processos” no Menu Principal e selecione o tipo de processo que deseja consultar: se é processo no órgão, no setor, tramitado ou arquivado. Após escolher o tipo de processo, você terá acesso ao número da solicitação, número do processo (NUP), departamento, assunto, data e hora do recebimento, e à sua situação e ao responsável pelo processo. Para visualizar as ações, clique no ícone “<i class="ph ph-download-simple"></i>“ no canto direito da tela. Lá, você pode adicionar um responsável para o processo, visualizar detalhes do processo, anexos e adicionar um despacho. Se o seu despacho precisar de um parecer, anexe-o no campo correspondente. Você pode comentar e visualizar comentários referentes ao processo, bem como o histórico. Para obter mais informações sobre prazos e atividades, clique no ícone “<i class="ph-thin ph-plus"></i>“ no canto esquerdo da aba inicial de “Solicitações”.`
          },
          {
            title: "Como realizo a Consulta de Processo?",
            text: `Para realizar a consulta de processos acesse “Consulta de Processos” na aba de Menu, lá irá conter diversas informações como  N° da solicitação, Número do processo (NUP), departamento, assunto, data e hora de recebimento e a situação do processo. Para ver os detalhes do processo clique em “<i class="ph ph-eye"></i>“  Lá, você pode adicionar um responsável para o processo, visualizar detalhes do processo, anexos e adicionar um despacho. Se o seu despacho precisar de um parecer, anexe-o no campo correspondente. Você pode comentar e visualizar comentários referentes ao processo, bem como o histórico e gerar pdf do processo. Para obter mais informações sobre prazos e atividades, clique no ícone “<i class="ph-thin ph-plus"></i>“ no canto esquerdo da aba inicial de “Solicitações”.`
          },
          {
            title: "Como realizo a Consulta de Usuários?",
            text: `Para realizar a consulta de assuntos, acesse o “Menu Principal” na aba “Configurações” na sessão “Assuntos”. Lá, constarão o nome do assunto, o órgão responsável, departamento responsável, o prazo do processo e o prazo de correção. Em “Ações”, você terá a opção de editar o assunto no ícone “<i class="ph ph-pencil"></i>“, onde poderá editar o órgão responsável, departamento responsável, o assunto, o prazo do processo e o prazo de correção. Para criar um novo assunto, clique em “Novo Assunto” na parte superior da tela, preencha as informações necessárias e clique em salvar.`
          },
          {
            title: "Como realizo a Consulta de Órgãos?",
            text: `Para realizar a consulta de órgãos, acesse o “Menu Principal” na aba “Configurações” e selecione a sessão “Órgão”. Lá, você encontrará o nome do órgão e sua sigla. Em “Ações” no icone “<i class="ph ph-pencil"></i>“, você poderá editar as informações do órgão, como nome, sigla, inícios de ofícios, de memorandos, portarias, certidões, declarações, instruções normativas, comunicação interna, secretário e secretário Executivo. Para cadastrar um novo órgão, clique na opção “Novo órgão”, preencha as credenciais necessárias e clique em “Salvar”.
            `
          },
          {
            title: "Como realizo a Consulta de Assuntos?",
            text: `Para realizar a consulta de assuntos, acesse o “Menu Principal” na aba “Configurações” na sessão “Assuntos”. Lá, constarão o nome do assunto, o órgão responsável, departamento responsável, o prazo do processo e o prazo de correção. Em “Ações”, você terá a opção de editar o assunto no ícone “<i class="ph ph-pencil"></i>“, onde poderá editar o órgão responsável, departamento responsável, o assunto, o prazo do processo e o prazo de correção. Para criar um novo assunto, clique em “Novo Assunto” na parte superior da tela, preencha as informações necessárias e clique em salvar.`
          },
          {
            title: "Como realizo a Consulta de Departamentos?",
            text: `Para realizar a consulta de departamentos, acesse o “Menu Principal” na aba “Configuração” na sessão “Departamentos”. Lá, irá conter o nome do departamento e o órgão responsável. Em “Ações”, você terá a opção de editar o departamento no ícone “<i class="ph ph-pencil"></i>“ Para criar um novo departamento, acesse “Novo departamento” no canto superior da tela, preencha o órgão responsável e o nome, e clique em salvar.`
          }
        ];
      case UserGroupEnum.ADMINISTRADOR_ORGAO:
        return [
          {
            title: "Como visualizar o meu Perfil?",
            text: `Para visualizar o perfil do usuário, clique no ícone “<i class="ph ph-user"></i>“  localizado na parte superior, ao lado das notificações. Nesta seção, você encontrará o nome do usuário e seu tipo. A aba “Perfil' permite que você acesse suas informações, como Nome, CPF e E-mail, juntamente com seu status atual e dados profissionais. Também é exibido o botão “Sair” caso deseje fazer logout.`
          },
          {
            title: "Como escolher o tipo de usuário?",
            text: `Ao fazer login no Protocolize, você será apresentado com uma mensagem de "Escolher tipo de usuário", onde terá a opção de continuar como usuário de triagem ou mudar para o perfil de usuário cidadão. Se desejar prosseguir como usuário de triagem, clique em "Continuar como Triagem"; caso prefira mudar para o usuário cidadão, clique em "Trocar usuário para cidadão". Se desejar que sua escolha seja lembrada para futuros acessos, marque a caixa "Lembrar minha escolha". `
          },
          {
            title: "Como acesso a Gestão de Processos?",
            text: `Para visualizar a gestão de processos, clique em “Gestão de Processos” no Menu Principal e selecione o tipo de processo que deseja consultar: se é processo no órgão, no setor, tramitado ou arquivado. Após escolher o tipo de processo, você terá acesso ao número da solicitação, número do processo (NUP), departamento, assunto, data e hora do recebimento, e à sua situação e ao responsável pelo processo. Para visualizar as ações, clique no ícone “<i class="ph ph-download-simple"></i>“ no canto direito da tela. Lá, você pode adicionar um responsável para o processo, visualizar detalhes do processo, anexos e adicionar um despacho. Se o seu despacho precisar de um parecer, anexe-o no campo correspondente. Você pode comentar e visualizar comentários referentes ao processo, bem como o histórico. Para obter mais informações sobre prazos e atividades, clique no ícone “<i class="ph-thin ph-plus"></i>“ no canto esquerdo da aba inicial de “Solicitações”.`
          },
          {
            title: "Como realizo a Consulta de Usuários?",
            text: `Para realizar a consulta de assuntos, acesse o “Menu Principal” na aba “Configurações” na sessão “Assuntos”. Lá, constarão o nome do assunto, o órgão responsável, departamento responsável, o prazo do processo e o prazo de correção. Em “Ações”, você terá a opção de editar o assunto no ícone “<i class="ph ph-pencil"></i>“, onde poderá editar o órgão responsável, departamento responsável, o assunto, o prazo do processo e o prazo de correção. Para criar um novo assunto, clique em “Novo Assunto” na parte superior da tela, preencha as informações necessárias e clique em salvar.`
          },
          {
            title: "Como realizo a Consulta de Assuntos?",
            text: `Para realizar a consulta de assuntos, acesse o “Menu Principal” na aba “Configurações” na sessão “Assuntos”. Lá, constarão o nome do assunto, o órgão responsável, departamento responsável, o prazo do processo e o prazo de correção. Em “Ações”, você terá a opção de editar o assunto no ícone “<i class="ph ph-pencil"></i>“, onde poderá editar o órgão responsável, departamento responsável, o assunto, o prazo do processo e o prazo de correção. Para criar um novo assunto, clique em “Novo Assunto” na parte superior da tela, preencha as informações necessárias e clique em salvar.`
          },
          {
            title: "Como realizo a Consulta de Departamentos?",
            text: `Para realizar a consulta de departamentos, acesse o “Menu Principal” na aba “Configuração” na sessão “Departamentos”. Lá, irá conter o nome do departamento e o órgão responsável. Em “Ações”, você terá a opção de editar o departamento no ícone “<i class="ph ph-pencil"></i>“ Para criar um novo departamento, acesse “Novo departamento” no canto superior da tela, preencha o órgão responsável e o nome, e clique em salvar.`
          }
        ];
      case UserGroupEnum.MEMBRO_DEPARTAMENTO:
        return [
          {
            title: "Como visualizar o meu Perfil?",
            text: `Para visualizar o perfil do usuário, clique no ícone “<i class="ph ph-user"></i>“  localizado na parte superior, ao lado das notificações. Nesta seção, você encontrará o nome do usuário e seu tipo. A aba “Perfil' permite que você acesse suas informações, como Nome, CPF e E-mail, juntamente com seu status atual e dados profissionais. Também é exibido o botão “Sair” caso deseje fazer logout.`
          },
          {
            title: "Como escolher o tipo de usuário?",
            text: `Ao fazer login no Protocolize, você será apresentado com uma mensagem de "Escolher tipo de usuário", onde terá a opção de continuar como usuário de triagem ou mudar para o perfil de usuário cidadão. Se desejar prosseguir como usuário de triagem, clique em "Continuar como Triagem"; caso prefira mudar para o usuário cidadão, clique em "Trocar usuário para cidadão". Se desejar que sua escolha seja lembrada para futuros acessos, marque a caixa "Lembrar minha escolha". `
          },
          {
            title: "Como acesso a Gestão de Processos?",
            text: `Para visualizar a gestão de processos, clique em “Gestão de Processos” no Menu Principal e selecione o tipo de processo que deseja consultar: se é processo no órgão, no setor, tramitado ou arquivado. Após escolher o tipo de processo, você terá acesso ao número da solicitação, número do processo (NUP), departamento, assunto, data e hora do recebimento, e à sua situação e ao responsável pelo processo. Para visualizar as ações, clique no ícone “<i class="ph ph-download-simple"></i>“ no canto direito da tela. Lá, você pode adicionar um responsável para o processo, visualizar detalhes do processo, anexos e adicionar um despacho. Se o seu despacho precisar de um parecer, anexe-o no campo correspondente. Você pode comentar e visualizar comentários referentes ao processo, bem como o histórico. Para obter mais informações sobre prazos e atividades, clique no ícone “<i class="ph-thin ph-plus"></i>“ no canto esquerdo da aba inicial de “Solicitações”.`
          },
          {
            title: "Como realizo a Consulta de Usuários?",
            text: `Para realizar a consulta de assuntos, acesse o “Menu Principal” na aba “Configurações” na sessão “Assuntos”. Lá, constarão o nome do assunto, o órgão responsável, departamento responsável, o prazo do processo e o prazo de correção. Em “Ações”, você terá a opção de editar o assunto no ícone “<i class="ph ph-pencil"></i>“, onde poderá editar o órgão responsável, departamento responsável, o assunto, o prazo do processo e o prazo de correção. Para criar um novo assunto, clique em “Novo Assunto” na parte superior da tela, preencha as informações necessárias e clique em salvar.`
          },
          {
            title: "Como realizo a Consulta de Assuntos?",
            text: `Para realizar a consulta de assuntos, acesse o “Menu Principal” na aba “Configurações” na sessão “Assuntos”. Lá, constarão o nome do assunto, o órgão responsável, departamento responsável, o prazo do processo e o prazo de correção. Em “Ações”, você terá a opção de editar o assunto no ícone “<i class="ph ph-pencil"></i>“, onde poderá editar o órgão responsável, departamento responsável, o assunto, o prazo do processo e o prazo de correção. Para criar um novo assunto, clique em “Novo Assunto” na parte superior da tela, preencha as informações necessárias e clique em salvar.`
          },
          {
            title: "Como realizo a Consulta de Departamentos?",
            text: `Para realizar a consulta de departamentos, acesse o “Menu Principal” na aba “Configuração” na sessão “Departamentos”. Lá, irá conter o nome do departamento e o órgão responsável. Em “Ações”, você terá a opção de editar o departamento no ícone “<i class="ph ph-pencil"></i>“ Para criar um novo departamento, acesse “Novo departamento” no canto superior da tela, preencha o órgão responsável e o nome, e clique em salvar.`
          }
        ];
      case UserGroupEnum.DIRETOR_DEPARTAMENTO:
        return [
          {
            title: "Como visualizar o meu Perfil?",
            text: `Para visualizar o perfil do usuário, clique no ícone “<i class="ph ph-user"></i>“  localizado na parte superior, ao lado das notificações. Nesta seção, você encontrará o nome do usuário e seu tipo. A aba “Perfil' permite que você acesse suas informações, como Nome, CPF e E-mail, juntamente com seu status atual e dados profissionais. Também é exibido o botão “Sair” caso deseje fazer logout.`
          },
          {
            title: "Como escolher o tipo de usuário?",
            text: `Ao fazer login no Protocolize, você será apresentado com uma mensagem de "Escolher tipo de usuário", onde terá a opção de continuar como usuário de triagem ou mudar para o perfil de usuário cidadão. Se desejar prosseguir como usuário de triagem, clique em "Continuar como Triagem"; caso prefira mudar para o usuário cidadão, clique em "Trocar usuário para cidadão". Se desejar que sua escolha seja lembrada para futuros acessos, marque a caixa "Lembrar minha escolha". `
          },
          {
            title: "Como acesso a Gestão de Processos?",
            text: `Para visualizar a gestão de processos, clique em “Gestão de Processos” no Menu Principal e selecione o tipo de processo que deseja consultar: se é processo no órgão, no setor, tramitado ou arquivado. Após escolher o tipo de processo, você terá acesso ao número da solicitação, número do processo (NUP), departamento, assunto, data e hora do recebimento, e à sua situação e ao responsável pelo processo. Para visualizar as ações, clique no ícone “<i class="ph ph-download-simple"></i>“ no canto direito da tela. Lá, você pode adicionar um responsável para o processo, visualizar detalhes do processo, anexos e adicionar um despacho. Se o seu despacho precisar de um parecer, anexe-o no campo correspondente. Você pode comentar e visualizar comentários referentes ao processo, bem como o histórico. Para obter mais informações sobre prazos e atividades, clique no ícone “<i class="ph-thin ph-plus"></i>“ no canto esquerdo da aba inicial de “Solicitações”.`
          },
          {
            title: "Como realizo a Consulta de Usuários?",
            text: `Para realizar a consulta de assuntos, acesse o “Menu Principal” na aba “Configurações” na sessão “Assuntos”. Lá, constarão o nome do assunto, o órgão responsável, departamento responsável, o prazo do processo e o prazo de correção. Em “Ações”, você terá a opção de editar o assunto no ícone “<i class="ph ph-pencil"></i>“, onde poderá editar o órgão responsável, departamento responsável, o assunto, o prazo do processo e o prazo de correção. Para criar um novo assunto, clique em “Novo Assunto” na parte superior da tela, preencha as informações necessárias e clique em salvar.`
          },
          {
            title: "Como realizo a Consulta de Assuntos?",
            text: `Para realizar a consulta de assuntos, acesse o “Menu Principal” na aba “Configurações” na sessão “Assuntos”. Lá, constarão o nome do assunto, o órgão responsável, departamento responsável, o prazo do processo e o prazo de correção. Em “Ações”, você terá a opção de editar o assunto no ícone “<i class="ph ph-pencil"></i>“, onde poderá editar o órgão responsável, departamento responsável, o assunto, o prazo do processo e o prazo de correção. Para criar um novo assunto, clique em “Novo Assunto” na parte superior da tela, preencha as informações necessárias e clique em salvar.`
          },
          {
            title: "Como realizo a Consulta de Departamentos?",
            text: `Para realizar a consulta de departamentos, acesse o “Menu Principal” na aba “Configuração” na sessão “Departamentos”. Lá, irá conter o nome do departamento e o órgão responsável. Em “Ações”, você terá a opção de editar o departamento no ícone “<i class="ph ph-pencil"></i>“ Para criar um novo departamento, acesse “Novo departamento” no canto superior da tela, preencha o órgão responsável e o nome, e clique em salvar.`
          }
        ];
    }
    return [];
  }
}
