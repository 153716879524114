import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/app-core/lib/global.service';
import { DashboardService } from 'src/app/app-core/services/dashboard.service';

@Component({
    selector: 'dashboard-triagem',
    templateUrl: './dashboard-triagem.component.html',
    styleUrls: ['../../pages/dashboard/dashboard.component.scss'],
    providers: [DashboardService],
})
export class DashboardTriagemComponent implements OnInit {
    constructor(
        public global: GlobalService,
        public dashboardService: DashboardService,
    ) {}

    public requestsAmount: number;
    public requestsInCorrection: number;
    public acceptedRequests: number;
    public requestsInEvaluation: number;

    public isChartLoading: boolean = true;

    async ngOnInit(): Promise<void> {
        this.requestsAmount = await this.dashboardService.getTotal(
            'requestService',
            `"SOL_CodigoOrgao" = ${this.global.user().USR_CodigoOrgao}`,
        );

        this.requestsInCorrection = await this.dashboardService.getTotal(
            'requestService',
            `"SOL_CodigoOrgao" = ${this.global.user().USR_CodigoOrgao} and "SOL_Status" = 2`,
        );

        this.requestsInEvaluation = await this.dashboardService.getTotal(
            'requestService',
            `"SOL_CodigoOrgao" = ${this.global.user().USR_CodigoOrgao} and "SOL_Status" = 0`,
        );

        this.acceptedRequests = await this.dashboardService.getTotal(
            'requestService',
            `"SOL_CodigoOrgao" = ${this.global.user().USR_CodigoOrgao} and "SOL_Status" = 1`,
        );

        setTimeout(() => {
            this.isChartLoading = false;
        }, 1000);
    }
}
