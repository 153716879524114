import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { GerinusBD } from '../lib/gerinus-bd.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'gerinus-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
    private ref: NgbModalRef;

    @ViewChild('content') content;

    @Input() title: string;
    @Input() entity: string;
    @Input() icon: string;
    @Input() owner: GerinusBD;
    @Input() size: string = 'custom-lg';
    @Input() lenghtList: Number;

    constructor(public modal: NgbModal) {}

    ngOnInit() {}

    public openModal() {
        this.ref = this.modal.open(this.content, {
            centered: true,
            size: this.size,
            keyboard: false,
            backdrop: 'static',
            ariaLabelledBy: 'modal-basic-title',
        });
        this.ref.result.then(
            (result) => {},
            (reason) => {},
        );
    }

    public closeModal(ok = true) {
        if (!ok && this.owner && this.owner.entity) {
            this.owner.entity = null;
        }
        this.ref.close();
    }
}
