import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GerinusService } from '../lib/gerinus.service';

@Injectable()
export class MenuService extends GerinusService {
    constructor(public http: HttpClient) {
        super(http);
        this.entityName = 'menu';
        this.idField = 'MNU_CodigoMenu';
    }

    public sideMenu(userId: number) {
        return this.http.post(this.apiURL() + '/api/menu', { userId }, this.headers());
    }
}
