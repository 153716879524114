import { Directive, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[RequiredInput]'
})
export class RequiredInputDirective implements AfterViewInit {

  constructor(public el: ElementRef, private ngControl: NgControl, private renderer: Renderer2) { }

  ngAfterViewInit() {
    this.setInvalid();
  }

  private setInvalid() {
    const control = this.ngControl.control;
    control.valueChanges.subscribe(() => {
      if (control.invalid && (control.touched || control.dirty)) {
        this.renderer.addClass(this.el.nativeElement, 'invalid-input');
      } else {
        this.renderer.removeClass(this.el.nativeElement, 'invalid-input');
      }
    });
  }
}