import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GerinusService } from '../lib/gerinus.service';

@Injectable({
  providedIn: 'root'
})
export class SubjectAttachmentService extends GerinusService {

  constructor(public http: HttpClient) {
    super(http);
    this.entityName = 'assunto_anexo';
    this.model = 'AssuntoAnexoModel'
    this.idField = 'ASX_CodigoAssuntoAnexo';
}
}
