// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    // apiURL: 'http://localhost:3333',
    // projectURL: 'http://localhost:4200',
    apiURL: 'https://api-protocolo.gerin.us',
    projectURL: 'https://protocolo.gerin.us',
    fiscoURL: 'https://homologafisco.pmsga.ce.gov.br/api/protocolos',
    govBR: {
        url: 'https://sso.staging.acesso.gov.br',
        clientID: 'web.hmlproto.pmsga.ce.gov.br',
        returnURL: 'https://web.hmlproto.pmsga.ce.gov.br/principal',
        secret: 'ALi9RrxHK9WD22pyS99tBGhyNsH_F9teJ05IPqmSE6wUuCVte0qFgUg4uohMkf7P9QOofO05s0sbxLR33MxAQvk',
        logoutUrl: 'https://sso.staging.acesso.gov.br/logout?post_logout_redirect_uri=https://web.hmlproto.pmsga.ce.gov.br/principal?logout=true'
    },
    secretKey: 'protosga23',
    cryptoSecret: '8b2a78c3cbf28ad5812e0b8a1742ae2b9f49ab02d54ff4762ef2df6af417e32f'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
