import { ProcessService } from 'src/app/app-core/services/process.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from '../../app-core/lib/global.service';
import { IProccess } from 'src/app/interfaces/proccess.interface';

@Component({
    selector: 'app-view-public-proccess',
    templateUrl: './view-public-proccess.component.html',
    styleUrls: ['./view-public-proccess.component.scss'],
    providers: [ProcessService],
})
export class ViewPublicProccessComponent {
    public processo: IProccess;
    public validate = false;

    constructor(
        private processoService: ProcessService,
        private route: ActivatedRoute,
        private global: GlobalService,
    ) {
        this.route.params.subscribe({
            next: (params: any) => {
                if (params.validate) {
                    this.validate = true;
                }
                this.processoService.publicProcess(params.id).subscribe({
                    next: (response: any) => {
                        if (response.ok) {
                            this.processo = response.entity;
                            this.sortDocuments();

                        } else {
                            this.global.swal.fire(response.swal).then((result) => {
                                if (result.isConfirmed || result.isDismissed) {
                                    this.goToLandingPage();
                                }
                            });
                        }
                    },
                });
            },
        });
    }

    goToLandingPage() {
        this.global.router.navigate(['/principal']);
    }

    private sortDocuments() {
        let filteredDocs = [];
        for (const documento of this.processo.documentos) {
            if (documento.SOLA_Status != 0) {
                let filtered = this.processo.documentos.filter((item) => item.SOLA_Tipo == documento.SOLA_Tipo);
                filteredDocs.push(filtered);
            }
        }
        this.processo.documentos = filteredDocs;
    }
}
