import { GerinusService } from './gerinus.service';
import { GlobalService } from './global.service';
import { ModalComponent } from '../layout/modal.component';
import $ from 'jquery';
import { ViewChild, Directive } from '@angular/core';

interface IFilters {
    name: string;
    columName: string;
    orColumName?: string;
    type?: string;
}

@Directive()
export class GerinusBD {
    @ViewChild('modal') modal;

    public editing = false;
    public filterWhere = null;

    public useFormData = false;
    public entity = null;
    public list = null;
    public editClass: ModalComponent = null;
    public pagination = null;
    public silent = false;
    public filterBy: string;
    public filters: IFilters[] = [];
    public showEmptyData: boolean = true;
    public showHeader: boolean = true;
    public showPaginate: boolean = true;

    public skeletonTableRows: number[] = Array.from({ length: 5 });

    constructor(
        public global: GlobalService,
        public service: GerinusService,
    ) {
        let editTimer = setInterval(() => {
            if (this.modal) {
                this.editClass = this.modal;
                clearInterval(editTimer);
            }
        }, 500);
    }

    public afterCreate(afterList: () => void = null) {
        this.listAll(1, afterList);
    }

    public defaults() {
        return {};
    }

    public listCondition() {
        return null;
    }

    public transform(entity) {
        return entity;
    }

    public canSave(id = null) {
        let invalids: any = [];

        if (id) {
            invalids = $(id + ' .ng-invalid');
        } else {
            invalids = $('.ng-invalid');
        }

        let message = '';

        for (let i = 0; i < invalids.length; i++) {
            if ($(invalids[i]).attr('placeholder')) {
                if (message != '') message = message + '<br>';
                message = message + $(invalids[i]).attr('placeholder');
            }
        }
        return {
            ok: invalids.addClass('ng-touched').length <= 0,
            message: message,
        };
    }

    public save(afterSave = null) {
        let canSave = this.canSave();
        if (canSave.ok) {
            if (!this.silent) this.global.startLoading('Salvando...');
            if (this.silent) this.global.startLoading('Carregando...');
            this.service.save(this.entity, this.useFormData).subscribe((response: any) => {
                if (response.ok) {
                    if (response.entity) {
                        let idx = this.list.findIndex(
                            (item) => item[this.service.idField] == response.entity[this.service.idField],
                        );
                        if (idx >= 0) {
                            this.list[idx] = response.entity;
                        } else {
                            this.list.unshift(response.entity);
                        }
                    }
                    this.editing = false;
                    this.global.stopLoading();
                    if (!this.silent) {
                        this.global.swal.fire({
                            title: 'Salvar!',
                            text: response.message,
                            icon: response.message_type,
                        });
                    }
                    if (this.editClass) {
                        if (!this.silent) {
                            this.editClass.closeModal();
                        }
                    }
                    if (afterSave) {
                        afterSave();
                    } else {
                        this.entity = null;
                    }
                    this.listAll();
                } else {
                    this.global.stopLoading();
                    this.global.swal.fire({
                        title: 'Erro!',
                        text: response.message,
                        icon: response.message_type,
                    });
                }
            });
        } else {
            this.global.swal.fire('Erro', canSave.message, 'error');
        }
    }

    public add(title = 'CADASTRO DE') {
        this.editing = true;
        this.entity = this.defaults();
        if (this.editClass) {
            this.editClass.openModal();
            this.modal.title = title;
            this.modal.icon = 'ph-plus';
        }
    }

    public edit(id) {
        this.editing = true;
        this.global.startLoading();
        this.entity = false;
        this.service.getEntity(id).subscribe((response: any) => {
            this.entity = this.transform(response.entity);
            if (this.editClass) {
                this.editClass.openModal();
                this.modal.title = 'ATUALIZAÇÃO DE';
                this.modal.icon = 'ph-pencil-simple';
            }
            this.global.stopLoading();
        });
    }

    public delete(id, afterDelete = null) {
        this.global.swal
            .fire({
                title: 'Remover?',
                text: 'Você tem certeza que deseja remover este registro?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
            })
            .then((result) => {
                if (result.value) {
                    this.global.startLoading('Removendo Registro...');
                    setTimeout(() => {
                        this.service.delete(id).subscribe((response: any) => {
                            if (response.ok) {
                                this.global.stopLoading();
                                this.global.swal.fire({
                                    title: 'Removido!',
                                    text: response.message,
                                    icon: response.message_type,
                                });
                                if (afterDelete) {
                                    afterDelete();
                                }
                            } else {
                                this.global.stopLoading();
                                this.global.swal.fire({
                                    title: 'Erro!',
                                    text: response.message,
                                    icon: response.message_type,
                                });
                            }
                            this.listAll();
                        });
                    }, 100);
                }
            });
    }

    public paginateMenu() {
        let result = [];
        if (this.pagination) {
            let page = 1;
            while (page <= this.pagination.last_page) {
                result.push(page);
                page++;
                if (page == 4 && this.pagination.last_page > 6) {
                    result.push(0);
                    page = this.pagination.last_page - 2;
                }
            }
        }
        return result;
    }

    public listAll(page = 1, afterList: () => void = null) {
        if (this.service.entityName) {
            this.list = null;
            this.pagination = null;
            this.list = null;
            setTimeout(() => {
                this.service.listAll(this.filterCondition(), page).subscribe((response: any) => {
                    if (response.data) {
                        this.list = response.data;
                        if (response.meta) {
                            this.pagination = response.meta;
                        }
                    } else {
                        this.list = response;
                    }
                    if (afterList) {
                        afterList();
                    }
                });
            }, 100);
        }
    }

    public filterCondition(): string {
        let where;

        if (!this.filterWhere) {
            return this.listCondition();
        }

        if (this.listCondition()) {
            where = `${this.listCondition()} and ${this.filterWhere}`;
        }
        else {
            return this.filterWhere;
        }
        return where;
    }

    getFilter(event: any) {
        if (event.name.length > 0) {
            this.filterWhere = `"${event.columName}"::TEXT ILIKE '%' || LOWER('${event.name}') || '%'`;
            if (event.orColumName) {
                this.filterWhere += `OR "${event.orColumName}"::TEXT ILIKE '%' || LOWER('${event.name}') || '%'`;
            }
        }
        else {
            this.filterWhere = null;
        }
        this.listAll();
    }
}
