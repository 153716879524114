import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsToDays'
})
export class SecondsToDaysPipe implements PipeTransform {
  transform(seconds: number): string {
    if (!seconds) {
      return '';
    }

    if (seconds < 60) {
      return 'Menos de 1 minuto';
    }

    const days = Math.floor(seconds / (3600 * 24));
    const hours = Math.floor((seconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    let result = '';

    if (days > 0) {
      result += days === 1 ? '1 dia, ' : `${days} dias, `;
    }

    if (hours > 0) {
      result += hours === 1 ? '1 hora' : `${hours} horas`;
    }

    if (minutes > 0) {
      if (hours > 0 || days > 0) {
        result += ' e ';
      }
      result += minutes === 1 ? '1 minuto' : `${minutes} minutos`;
    }

    return result;
  }
}
