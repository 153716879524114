import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-modal-info',
    templateUrl: './modal-info.component.html',
    styleUrls: ['./modal-info.component.scss'],
})
export class RequestModalComponent implements OnInit {
    @Input() title: string;
    @Input() name: string;
    @Input() icon: 'primary' | 'success' | 'warning' | 'danger';
    @Input() theme: string;
    @Input() dataBackdrop;
    @Input() dataKeyboard;
    @Input() size: 'sm' | 'md' | 'lg' = 'md';

    constructor() {}

    ngOnInit(): void {}
}
