import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CryptoService } from 'src/app/app-core/services/crypto.service';
import { ProcessService } from 'src/app/app-core/services/process.service';
import { IProcessUserFeedbackSchema } from 'src/app/interfaces/proccess.interface';
import * as CryptoJS from 'crypto-js';
import { ProcessEvaluationService } from 'src/app/app-core/services/process-evaluation.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { GlobalService } from 'src/app/app-core/lib/global.service';

const cryptoSecret = '8b2a78c3cbf28ad5812e0b8a1742ae2b9f49ab02d54ff4762ef2df6af417e32f';

@Component({
  selector: 'app-process-feedback',
  templateUrl: './process-feedback.component.html',
  styleUrls: ['./process-feedback.component.scss'],
  providers: [ProcessService, CryptoService, ProcessEvaluationService, MessageService]
})

export class ProcessFeedbackComponent implements OnInit {

  constructor(
    private processService: ProcessService,
    private route: ActivatedRoute,
    private processEvaluationService: ProcessEvaluationService,
    private messageService: MessageService,
    public global: GlobalService
  ) { }

  selectedRating: number;
  suggestions: string;
  userId: string;
  processId: string;
  isProcessAlreadyRated: boolean = false;
  isRatingSubmitted: boolean = false;

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const decodedProcessId = decodeURIComponent(params['process']);
      const decodedUserId = decodeURIComponent(params['user']);

      this.processId = CryptoJS.AES.decrypt(decodedProcessId, cryptoSecret).toString(CryptoJS.enc.Utf8);
      this.userId = CryptoJS.AES.decrypt(decodedUserId, cryptoSecret).toString(CryptoJS.enc.Utf8);

      this.isProcessAlreadyEvaluated(this.processId).subscribe(isEvaluated => {
        if (isEvaluated) {
          this.isProcessAlreadyRated = true;
          this.messageService.add({
            severity: 'error',
            icon: 'ph ph-warning',
            closable: true,
            life: 5000,
            summary: 'Não é possível avaliar este processo!',
            detail: 'O processo que você está tentando avaliar já foi avaliado.',
          });

          setTimeout(() => {
            this.global.router.navigate(['/principal'])
          }, 4000)
        }
      });
    });
  }

  isProcessAlreadyEvaluated(processId: string | number): Observable<boolean> {
    return this.processService.listProcessFeedback(Number(processId))
      .pipe(
        map((response: any) => response.data != null)
      );
  }

  submitFeedback() {

    const userFeedbackData: IProcessUserFeedbackSchema = {
      rating: this.selectedRating,
      suggestions: this.suggestions,
      userId: this.userId,
      processId: this.processId
    }

    if (!this.selectedRating) {
      this.messageService.add({
        severity: 'error',
        icon: 'ph ph-warning',
        closable: true,
        life: 3000,
        summary: 'Não foi possível enviar feedback.',
        detail: 'Selecione uma avaliação antes de envir seu feedback.',
      });
    }


    this.processService.submitUserFeedback(userFeedbackData).subscribe({
      next: (response: any) => {
        if (response.ok) {
          this.isRatingSubmitted = true;
          this.messageService.add({
            severity: 'success',
            icon: 'ph ph-warning',
            closable: true,
            life: 3000,
            summary: 'Feedback enviado com sucesso!',
            detail: 'Obrigado por compartilhar sua opinião.',
          });

          setTimeout(() => {
            this.global.router.navigate(['/principal'])
          }, 4000)
        }
      }
    })
    
  }

}
