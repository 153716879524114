import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GerinusService } from '../lib/gerinus.service';

@Injectable()
export class NotificationService extends GerinusService {
    constructor(public http: HttpClient) {
        super(http);
        this.entityName = 'notificacao';
        this.idField = 'NOT_CodigoNotificacao';
    }

    public markAllAsRead(notificationIds: number[]) {
        return this.http.post(this.apiURL() + '/api/notification/mark-all-as-read', notificationIds, this.headers());
    }
}
