import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GerinusService } from '../lib/gerinus.service';
import { GlobalService } from '../lib/global.service';
import { Observable, throwError } from 'rxjs';

import Swal from 'sweetalert2';
import { IListScopeUserResponse, IUser } from 'src/app/interfaces/user';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { catchError } from 'rxjs/operators';
import { ICancellationEvaluationSchema } from 'src/app/interfaces/proccess.interface';

export enum UsersGroupEnum {
    ADMIN = 1,
    EVALUATION = 2,
    CITIZEN = 3,
    DEPARTMENT_DIRECTOR = 4,
    ORGANIZATION_ADMIN = 5,
    DEPARTMENT_MEMBER = 6,
    SECRETARY = 7,
}

@Injectable()
export class UserService extends GerinusService {
    constructor(
        public http: HttpClient,
        public global: GlobalService,
    ) {
        super(http);
        this.entityName = 'usuario';
        this.idField = 'USR_CodigoUsuario';
        this.companyField = 'USR_CodigoEmpresa';
        this.model = 'UserModel';
        this.orderBy = 'USR_Nome';
    }

    public swal = Swal;

    public getUserById(userId: number): Observable<any> {
        const url = `${this.apiURL}/usuarios/${userId}`; // Substitua pelo endpoint real do seu backend
        return this.http.get(url);
    }

    public isAdmin(): boolean {
        return this.global.user().GRP_Descricao == 'ADMINISTRADOR';
    }

    public isTriagem(): boolean {
        return this.global.user().GRP_Descricao == 'TRIAGEM';
    }

    public isCidadao(): boolean {
        return this.global.user().GRP_Descricao == 'CIDADAO';
    }

    public isDiretorDepartamento(): boolean {
        return this.global.user().GRP_Descricao == 'DIRETOR DEPARTAMENTO';
    }

    public isMembroDepartamento(): boolean {
        return this.global.user().GRP_Descricao == 'MEMBRO DEPARTAMENTO';
    }

    public isAdmOrgao(): boolean {
        return this.global.user().GRP_Descricao == 'ADMINISTRADOR ORGAO';
    }

    public isSecretario(): boolean {
        return this.global.user().GRP_Descricao == 'SECRETARIO';
    }

    public formatGroupName(groupId: string = ''): string {
        const formattedGropName = {
            1: 'ADMINISTRADOR',
            2: 'TRIAGEM',
            3: 'CIDADÃO',
            4: 'DIRETOR DE DEPARTAMENTO',
            5: 'ADMINISTRADOR DO ÓRGÃO',
            6: 'MEMBRO DO DEPARTAMENTO',
            7: 'SECRETÁRIO',
            8: 'ATENDENTE DE TRIBUTOS',
            9: 'ATENDENTE DE ARRECADAÇÃO'
        };

        if (groupId !== '') {
            return formattedGropName[groupId];
        }

        return formattedGropName[this.global.user().USG_CodigoGrupo];
    }

    handleChangeInternalUserGroup(rememberChoice: boolean) {
        const userRequest = {
            type: 'changeUserGroup',
            USR_LembrarEscolha: rememberChoice,
            USR_CodigoUsuario: this.global.user().USG_CodigoUsuario,
            USG_CodigoGrupo: 3,
            USG_CodigoGrupoOriginal: this.global.user().USG_CodigoGrupoOriginal
                ? this.global.user().USG_CodigoGrupoOriginal
                : this.global.user().USG_CodigoGrupo,
        };

        this.save(userRequest).subscribe({
            next: (response: any) => {
                Swal.fire({
                    title: 'Grupo de usuário Alterado',
                    text: `Seu acesso agora é como ${this.formatGroupName(response.entity.USG_CodigoGrupo)}`,
                    icon: 'success',
                    showCancelButton: true,
                    cancelButtonColor: '#417bff',
                    cancelButtonText: 'OK',
                    showConfirmButton: false,
                }).then(() => {
                    const userResponse = response.entity;
                    const userData = this.global.user();

                    userData.USG_CodigoGrupo = userResponse.USG_CodigoGrupo;
                    userData.USG_CodigoGrupoOriginal = userResponse.USG_CodigoGrupoOriginal;
                    userData.USR_Administrador = userResponse.USR_Administrador;
                    userData.GRP_CodigoGrupo = userResponse.USG_CodigoGrupo;
                    userData.GRP_Descricao = userResponse.GRP_Descricao;
                    userData.USR_LembrarEscolha = userResponse.USR_LembrarEscolha;

                    localStorage.setItem('user', JSON.stringify(userData));
                    window.location.reload();
                });
            },
        });
    }

    public getUser(): IUser {
        return JSON.parse(localStorage.getItem('user')) as IUser
    }

    public rememberChoice(userId: number) {
        return this.http.put(this.apiURL() + `/api/users/${userId}/remember-choice`, { USR_LembrarEscolha: true }, this.headers());
    }

    public generateListPDF(title: string, condition = null) {
        const doc = new jsPDF('p', 'mm', 'a4');
        doc.setFont('Inter-SemiBold');
        doc.text(title, doc.internal.pageSize.width / 2 || doc.internal.pageSize.getWidth() / 2, 15, { align: 'center' });
        this.listAll(condition, 0).subscribe({
            next: (response: IListScopeUserResponse) => {

                if (response.data && response.data.length > 0) {

                    const tableData = [];

                    for (let item of response.data) {
                        tableData.push([item.PE_Nome, item.GRP_Descricao, item.OR_Descricao, item.DEP_Descricao]);
                    }

                    const tableConfig = {
                        startY: 20,
                        margin: { top: 20 },
                        styles: {
                            fontSize: 10,
                        },
                        headStyles: {
                            fontSize: 8,
                        },
                        bodyStyles: {
                            fontSize: 8,
                        },
                    };

                    autoTable(doc, {
                        head: [['NOME', 'GRUPO DE USUÁRIO', 'ÓRGÃO', 'DEPARTAMENTO'],],
                        body: tableData,
                        ...tableConfig,
                        styles : {
                            valign: 'middle'
                        }
                    });

                    doc.save("Lista de Usuários.pdf");
                }
            }
        })
    }

    toggleToSecondaryUserGroup(userId: number, data: any): Observable<any> {
        return this.http.put(this.apiURL() + '/api/users/' + userId + '/toggle-to-secondary-group', { data }, this.headers())
        .pipe(
            catchError(this.handleError)
        )
    }

    private handleError(error: any): Observable<never> {
        console.error('An error occurred:', error);
        return throwError(() => new Error('Something went wrong; please try again later.'));
    }
}
