import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Message, MessageService } from 'primeng/api';
import { PersonService } from 'src/app/app-core/services/person.service';
import { ProcessMailService } from 'src/app/app-core/services/process-mail.service';
import { ProcessService } from 'src/app/app-core/services/process.service';
import { UserService } from 'src/app/app-core/services/user.service';
import { ValidationService } from 'src/app/app-core/services/validation.service';
import { IProccess } from 'src/app/interfaces/proccess.interface';

@Component({
    selector: 'app-mail-compose-modal',
    templateUrl: './mail-compose-modal.component.html',
    styleUrls: ['./mail-compose-modal.component.scss'],
    providers: [ProcessService, ProcessMailService, ValidationService, UserService, PersonService, MessageService],
})
export class MailComposeModalComponent implements OnInit {
    @ViewChild('subject') subject!: NgModel;
    @ViewChild('body') body!: NgModel;

    @Input() process: IProccess;

    constructor(
        private processMailService: ProcessMailService,
        private validationService: ValidationService,
        public userService: UserService,
        private personService: PersonService,
        private messageService: MessageService,
    ) {}

    processMailData = {
        to: '',
        copyTo: '',
        subject: '',
        body: '',
        recipientId: 0,
        processId: 0,
    };
    invalidEmailsMessage: string;
    isModalVisible: boolean = false;
    editorConfig = {
        base_url: '/tinymce',
        suffix: '.min',
        menubar: false,
        statusbar: false,
        plugins:
            'preview importcss searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons',
        toolbar:
            'undo redo | blocks | bold italic underline strikethrough | font formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
        fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',
        wordcount_display: false,
        language_url: '/assets/languages/pt_BR.js',
        language: 'pt_BR',
        content_style: 'body { font-family:Inter, sans-serif; font-size:14px; }',
    };

    ngOnInit(): void {
        this.getRecipientEmailAndId();

        this.processMailData.processId = this.process.PRC_CodigoProcesso;
    }

    showDialog() {
        this.isModalVisible = true;
    }

    getRecipientEmailAndId() {
        if (!this.userService.isCidadao()) {
            this.processMailData.to = this.process.PE_Email;
            this.processMailData.recipientId = this.process.PE_CodigoPessoa;
        } else {
            this.personService.getEntity(this.process.PRC_CodigoResponsavel).subscribe({
                next: (response: any) => {
                    this.processMailData.to = response.entity.PE_Email;
                    this.processMailData.recipientId = response.entity.PE_CodigoPessoa;
                },
            });
        }
    }

    hasErrors(): boolean {
        let hasInvalidEmails = false;

        if (this.processMailData.copyTo) {
            this.processMailData.copyTo.split(',').forEach((email) => {
                if (!this.validationService.emailValidator(email.trim())) {
                    hasInvalidEmails = true;

                    return;
                }
            });

            this.invalidEmailsMessage = hasInvalidEmails ? 'Há e-mails de cópia Inválidos' : '';
        }

        return !!this.subject.errors || !!this.body.errors || hasInvalidEmails;
    }

    async onSendEmail() {
        if (this.hasErrors()) {
            return;
        }

        await this.processMailService.save(this.processMailData).subscribe({
            next: () => {
                this.messageService.add({
                    severity: 'success',
                    closable: true,
                    summary: 'E-mail enviado com sucesso',
                });

                this.isModalVisible = false;
            },
        });
    }
}
