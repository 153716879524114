import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { GerinusBD } from 'src/app/app-core/lib/gerinus-bd.component';
import { GlobalService } from 'src/app/app-core/lib/global.service';
import { RequestAttachmentService } from 'src/app/app-core/services/requestAttachment.service';
import { UploadService } from 'src/app/app-core/services/upload.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-dropzone',
    templateUrl: './dropzone.component.html',
    styleUrls: ['./dropzone.component.scss'],
    providers: [GlobalService, UploadService, RequestAttachmentService],
})
export class DropzoneComponent extends GerinusBD implements OnInit {
    constructor(
        public global: GlobalService,
        public uploadService: UploadService,
        public anexoService: RequestAttachmentService,
    ) {
        super(global, anexoService);
        this.afterCreate();
    }

    public ngOnInit() {
        if (this.files) {
            this.renameFiles(this.files);
        }
    }

    @Input() title: string;
    @Input() description: string;
    @Input() organization?: string;
    @Input() variant: string = 'inline';
    @Input() mimeTypes: string[];
    @Input() isDisabled: boolean;
    @Input() typeDocument: string;
    @Input() files: any[];
    @Input() showDropzone: boolean = true;
    @Input() showDownloadButton: boolean = true;
    @Input() showDeleteButton: boolean = true;
    @Input() showPreview: boolean = true;
    @Input() showBlockUi: boolean = true;
    @Input() showSwalAfterUpload: boolean = true;
    @Input() limitFiles: number;
    @Input() showTime = true;
    @Input() maxFileSize: number = 40 * 1024 * 1024; // Padrão 40MB

    @Output() eventUploadedFile: any = new EventEmitter();
    @Output() deleteUploadedFile: any = new EventEmitter();

    public swal = Swal;
    public loading: boolean = false;

    allFiles: any = [];

    public defaults() {
        return {
            id: '',
        };
    }

    blockLimitFiles() {
        if (this.limitFiles && this.allFiles.length >= this.limitFiles) {
            this.global.swal.fire({
                title: 'Erro ao fazer o upload.',
                text: 'Limite de arquivos excedido.',
                icon: 'warning',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonText: 'Ok',
            });

            return false;
        }

        return true;
    }

    droppedFiles(file: any, organization?: string) {
        if (this.global.verifyFile(file, this.mimeTypes, this.maxFileSize)) {
            if (this.blockLimitFiles()) {
                this.allFiles.push(file[0]);
                this.upload(file[0], organization);
            }
        }
    }

    selectArquivo(file: any, organization?: string) {
        const files = file.srcElement.files;
        if (this.global.verifyFile(files, this.mimeTypes, this.maxFileSize)) {
            if (this.blockLimitFiles()) {
                this.upload(files[0], organization);
                this.allFiles.push(files[0]);

                file.srcElement.value = '';
            }
        }
    }

    deleteFromArray(idx: number) {
        this.allFiles.splice(idx, 1);

        this.deleteUploadedFile.emit({
            index: idx,
            typeDocument: this.typeDocument,
        });
    }

    upload(file: File, organization?: string) {
        this.global.startLoading(null, true);
        this.uploadService.upload({ file, organization }).subscribe({
            next: (response: any) => {
                if (response.files) {
                    this.global.stopLoading();

                    if (this.showSwalAfterUpload) {
                        this.global.swal.fire({
                            title: 'Tudo certo!',
                            text: 'Seu arquivo foi carregado com sucesso!',
                            icon: 'success',
                        });
                    }
                    this.eventUploadedFile.emit({
                        file: {
                            url: response.files[0],
                            name: file.name,
                            type: file.type,
                            typeDocument: this.typeDocument,
                        },
                    });
                }
            },
        });
    }

    renameFiles(file: any[]) {
        this.allFiles = this.files.map((file) => {
            return {
                id: file.SOLA_CodigoSolicitacaoAnexo,
                name: file.SOLA_Nome,
                anexo: file.SOLA_Anexo,
                status: file.SOLA_Status,
                dataUpload: file.SOLA_DataUpload,
            };
        });
    }

    anularDocumento(id: number, idx: number) {
        Swal.fire({
            title: 'Tem certeza que deseja anular este arquivo?',
            text: 'Esta ação não pode ser desfeita!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#417bff',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
        }).then((result) => {
            if (result.isConfirmed) {
                const anexo = {
                    SOLA_Status: 0,
                    SOLA_CodigoSolicitacaoAnexo: id,
                };

                this.anexoService.save(anexo).subscribe({
                    next: () => {
                        this.allFiles[idx].status = 0;

                        Swal.fire('Arquivo Anulado!', 'Seu arquivo foi anulado com sucesso.', 'success');
                        window.location.reload();
                    },
                    error: (error: any) => {
                        console.error('Erro ao anular o arquivo:', error);
                        Swal.fire('Erro ao anular o arquivo', 'Ocorreu um erro ao anular o arquivo.', 'error');
                    },
                });
            } else {
                Swal.fire('Operação Cancelada', 'O arquivo não foi anulado.', 'info');
            }
        });
    }

    download(name: string) {
        this.uploadService.download(name, this.organization).subscribe({
            next: (response: any) => {
                if (response.url) {
                    window.open(response.url, '_blank');
                }
            },
            error: (error: any) => {
                console.error('Erro no download:', error);
            },
        });
    }
}
