import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GerinusService } from '../lib/gerinus.service';

@Injectable()
export class DocumentService extends GerinusService {
    constructor(public http: HttpClient) {
        super(http);
        this.entityName = 'documento';
        this.idField = 'DOC_CodigoDocumento';
        this.model = 'DocumentoModel';
    }

    getVericityOfDocument(authKey: string) {
        return this.http.get(this.apiURL() + `/api/free/document/${authKey}`);
    }

    getDocumentType(routerType: string): string {
        switch (routerType) {
            case 'official-letter':
                return 'oficio';
            case 'memo':
                return 'memorando';
            case 'ordinances':
                return 'portaria';
            case 'certificates':
                return 'certidao';
            case 'normative-instructions':
                return 'instrucao-normativa';
            case 'declarations':
                return 'declaracao';
            case 'internal-comunications':
                return 'comunicacao-interna';
        }

        return '';
    }
}
