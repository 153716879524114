import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js';

import * as dayjs from 'dayjs';

import { GlobalService } from 'src/app/app-core/lib/global.service';
import { GerinusService } from 'src/app/app-core/lib/gerinus.service';
import { ProcessService } from 'src/app/app-core/services/process.service';
import { RequestService } from 'src/app/app-core/services/request.service';
import { OrganizationService } from 'src/app/app-core/services/organization.service';
import { UserService } from 'src/app/app-core/services/user.service';

// core components
import { chartOptions, parseOptions } from '../../variables/charts';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    providers: [GerinusService, ProcessService, RequestService, OrganizationService, UserService],
})
export class DashboardComponent implements OnInit {
    chart: Chart;
    lineChart: Chart;

    requestsAmount: number = 0;
    requestsInEvaluation: number = 0;
    requestsInCorrectionEvaluation: number = 0;
    requestsInCorrectionCitizen: number = 0;
    acceptedRequests: number = 0;

    timelyProcesses: number = 0;
    urgentProcesses: number = 0;
    tranferredProcess: number = 0;
    processesInSector: number = 0;
    processesAmount: number = 0;

    documentsToSign: number = 0;
    organizationsAmount: number = 0;
    usersAmount: number = 0;

    datasets: any;
    data: any;
    salesChart;

    isRememberChoiceChecked: boolean = false;
    groupToChange: string = this.userService.isCidadao()
        ? this.userService.formatGroupName(this.global.user().USG_CodigoGrupoOriginal)
        : 'Cidadão';

    constructor(
        public service: GerinusService,
        public global: GlobalService,
        public processService: ProcessService,
        public requestService: RequestService,
        public organizationService: OrganizationService,
        public userService: UserService
    ) { }

    async ngOnInit() {
        document.querySelector('title').text = 'Protocolize | Início';

        this.usersAmount = await this.getTotal('userService');
        this.requestsAmount = await this.getTotal('requestService');
        this.requestsInEvaluation = await this.getTotal(
            'requestService',
            `"SOL_Status" = 0 and "SOL_CodigoOrgao" = ${this.global.user().USR_CodigoOrgao}`,
        );
        this.processesAmount = await this.getTotal('processService');
        this.processesInSector = await this.getTotal(
            'processService',
            `"PRT_DepartamentoAtual" = ${this.global.user().PE_CodigoDepartamento} and "Status" != 3`,
        );
        this.tranferredProcess = await this.getTotal(
            'processService',
            `"PRT_CodigoDepartamentoTramitador" = ${this.global.user().PE_CodigoDepartamento}`,
        );

        this.organizationsAmount = await this.getTotal('orgaosService');
        this.requestsInCorrectionEvaluation = await this.getTotal(
            'requestService',
            `"SOL_Status" = 2 and "SOL_CodigoOrgao" = ${this.global.user().USR_CodigoOrgao}`,
        );
        this.requestsInCorrectionCitizen = await this.getTotal(
            'requestService',
            `"SOL_Status" = 2 and "SOL_CodigoSolicitante" = ${this.global.user().PE_CodigoPessoa}`,
        );
        this.acceptedRequests = await this.getTotal(
            'requestService',
            `"SOL_Status" = 1 and "SOL_CodigoSolicitante" = ${this.global.user().PE_CodigoPessoa}`,
        );

        this.processesAmount > 0 ? this.createChartProcesso() : '';

        this.requestsAmount > 0 ? this.createChartRequests() : '';

        const isRememberChoice = this.global.user().USR_LembrarEscolha;
        const isModalChangeGroupDisplayed = localStorage.getItem('modal-change-group-displayed');

        if (
            this.global.user().USR_UsuarioInterno &&
            isRememberChoice == false &&
            isModalChangeGroupDisplayed !== 'true'
        ) {
            localStorage.setItem('modal-change-group-displayed', 'true');
            this.global.showModalInfo('change-user-group');
        }
    }

    createChartProcesso() {
        let processLabels = [];
        let processBackgroundColors = [];
        let processData = [];

        if (this.userService.isDiretorDepartamento() || this.userService.isAdmin()) {
            processLabels = ['Resolvidos', 'Em Análise', 'Arquivados', 'Aguardando Análise', 'Aguardando Correção'];
            processBackgroundColors = ['#0BB07B', '#417bff', '#CDD5E0', '#11cdef', '#fb6340'];

            let countResolvedProcesses = 0;
            let countProcessesInEvaluation = 0;
            let countArchivedProcesses = 0;
            let countProcessWaitingEvaluation = 0;
            let countProcessWaitingCorrection = 0;

            let where = this.userService.isDiretorDepartamento()
                ? `"PRT_DepartamentoAtual" = ${this.global.user().PE_CodigoDepartamento} and "PRT_DataFim" IS NULL`
                : '"PRT_DataFim" IS NULL';

            this.processService.listAll(where, 0).subscribe({
                next: (response: any) => {
                    response.data.forEach((item) => {
                        switch (item.Status) {
                            case 1:
                                countProcessWaitingEvaluation++;
                                break;
                            case 2:
                                countProcessesInEvaluation++;
                                break;
                            case 3:
                                countArchivedProcesses++;
                                break;
                            case 4:
                                countResolvedProcesses++;
                                break;
                            case 5:
                                countProcessWaitingCorrection++;
                                break;
                            default:
                                break;
                        }
                        
                    });

                    processData.push(countResolvedProcesses);
                    processData.push(countProcessesInEvaluation);
                    processData.push(countArchivedProcesses);
                    processData.push(countProcessWaitingEvaluation);
                    processData.push(countProcessWaitingCorrection);

                    this.createChart(processLabels, processData, processBackgroundColors, 'processos');
                },
            });
        }
    }

    createChartRequests() {
        let requestLabels = [];
        let requestBackgroundColors = [];
        let requestsData = [];

        if (this.userService.isCidadao() || this.userService.isAdmin() || this.userService.isTriagem()) {
            requestLabels = ['Aceitas (Processos)', 'Em Triagem', 'Em Correção'];
            requestBackgroundColors = ['#0BB07B', '#417bff', '#e8a21a'];

            let countRequestsInEvaluation = 0;
            let countAcceptedRequests = 0;
            let countRequestsInCorrection = 0;

            let where = '';

            if (this.userService.isTriagem()) {
                where = `"SOL_CodigoOrgao" = ${this.global.user().USR_CodigoOrgao}`;
            } else if (this.userService.isCidadao()) {
                where = `"SOL_CodigoSolicitante" = ${this.global.user().PE_CodigoPessoa}`;
            }

            this.requestService.listAll(where, 0).subscribe({
                next: (response: any) => {
                    response.data.forEach((item) => {
                        if (item.SOL_Status === 1) {
                            countAcceptedRequests++;
                        } else if (item.SOL_Status === 0) {
                            countRequestsInEvaluation++;
                        } else if (item.SOL_Status === 2) {
                            countRequestsInCorrection++;
                        }
                    });

                    requestsData.push(countAcceptedRequests);
                    requestsData.push(countRequestsInEvaluation);
                    requestsData.push(countRequestsInCorrection);

                    this.createChart(requestLabels, requestsData, requestBackgroundColors, 'solicitacoes');
                },
            });
        }

        if (this.userService.isAdmin() || this.userService.isTriagem()) {
            const currentDate = new Date();
            const currentMonth = currentDate.getMonth() + 1;

            let where = '';

            if (this.userService.isTriagem()) {
                where = `"SOL_CodigoOrgao" = ${this.global.user().USR_CodigoOrgao}`;
            }

            this.requestService.listAll(where, 0).subscribe({
                next: (response: any) => {
                    const dias = Array.from({ length: 31 }, (_, i) => (i + 1).toString()); // Assumindo um máximo de 31 dias

                    const solicitacoesPorDia = new Array(dias.length).fill(0);

                    response.data.forEach((solicitacao) => {
                        const dataSolicitacao = new Date(solicitacao.SOL_DataSolicitacao);
                        if (dataSolicitacao.getMonth() + 1 === currentMonth) {
                            const dia = dataSolicitacao.getDate();
                            solicitacoesPorDia[dia - 1]++; // Ajustado para adicionar 1 ao índice
                        }
                    });

                    this.createBarChart(
                        dias.slice(0, currentDate.getDate()),
                        solicitacoesPorDia.slice(0, currentDate.getDate()),
                    );
                },
            });
        }
    }

    createChart(labels, data, backgroundColor, chartName) {
        setTimeout(() => {
          this.chart = new Chart(chartName, {
            type: 'doughnut',
            data: {
              labels,
              datasets: [
                {
                  data,
                  backgroundColor,
                },
              ],
            },
            options: {
              plugins: {
                tooltip: {
                  callbacks: {
                    label: function (context) {
                      let label = context.label || '';
                      if (label) {
                        label += ': ';
                      }
                      label += context.raw + ' (' + ((context.raw / data.reduce((a, b) => a + b, 0)) * 100).toFixed(2) + '%)';
                      return label;
                    }
                  }
                },
                legend: {
                  display: true,
                  position: 'top',
                  labels: {
                    generateLabels: function (chart) {
                      const data = chart.data;
                      if (data.labels.length && data.datasets.length) {
                        return data.labels.map((label, i) => {
                          const meta = chart.getDatasetMeta(0);
                          const dataset = data.datasets[0];
                          const value = dataset.data[i];
                          const total = dataset.data.reduce((a, b) => a + b, 0);
                          const percentage = ((value / total) * 100).toFixed(2);
      
                          return {
                            text: `${label}: ${percentage}%`,
                            fillStyle: dataset.backgroundColor[i],
                            strokeStyle: dataset.backgroundColor[i],
                            index: i
                          };
                        });
                      }
                      return [];
                    },
                    usePointStyle: true,
                    padding: 16,
                  },
                  onClick: null // Disable click functionality
                }
              }
            }
          });
        }, 1000);
      }

    createBarChart(days, requestsPerDay) {
        var chartOrders = document.getElementById('solicitacoes-bar-chart');

        parseOptions(Chart, chartOptions());

        setTimeout(() => {
            this.salesChart = new Chart(chartOrders, {
                type: 'bar',
                options: {
                    tooltips: {
                        callbacks: {
                            label: function (tooltipItem, data) {
                                const solicitacoes = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                                return `${solicitacoes} ${solicitacoes == 1 ? 'Solicitação' : 'Solicitações'}`;
                            },
                        },
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                    stepSize: 2,
                                },
                            },
                        ],
                        xAxes: [
                            {
                                ticks: {
                                    callback: function (value, index, values) {
                                        const currentDate = dayjs().format('MM');
                                        const formattedDate = value.toString().padStart(2, '0') + '/' + currentDate;
                                        return formattedDate;
                                    },
                                },
                            },
                        ],
                    },
                },
                data: {
                    labels: days,
                    datasets: [
                        {
                            data: requestsPerDay,
                        },
                    ],
                },
            });
        }, 1000);
    }

    async getTotal(entity: string, where: string = ''): Promise<number> {
        return new Promise<number>((resolve) => {
            if (this[entity]) {
                this[entity].listAll(where, 0).subscribe({
                    next: (response: any) => {
                        const total = response.data.length;
                        resolve(total);
                    },
                });
            } else {
                resolve(0);
            }
        });
    }

    closeModalChangeUserGroup(rememberChoice: boolean) {
        this.global.closeModalInfo('change-user-group');

        if (rememberChoice) {
            this.userService.rememberChoice(this.global.user().USR_CodigoUsuario).subscribe((response: any) => {
                console.log('USUÁRIO ATUALIZADO', response);
            });
        }
    }

    toggleCheckRememberChoice() {
        this.isRememberChoiceChecked = !this.isRememberChoiceChecked;
    }

    redirectToNewRequest() {
        this.global.router.navigate(['/requests/new']);
    }
}
