import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";

export class GenericValidator {
    constructor() { }

    static isValidCpfOrCnpj(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;
            if (value) {
                const cleanValue = value.replace(/[^\d]/g, '');

                if (cleanValue.length === 11) {
                    return GenericValidator.isValidCpf(cleanValue) ? null : { cpfNotValid: true };
                } else if (cleanValue.length === 14) {
                    return GenericValidator.isValidCnpj(cleanValue) ? null : { cnpjNotValid: true };
                } else {
                    return { cpfOrCnpjNotValid: true };
                }
            }
            return null;
        };
    }

    private static isValidCpf(cpf: string): boolean {
        let numbers, digits, sum, i, result, equalDigits;
        equalDigits = 1;

        if (cpf.length !== 11) {
            return false;
        }

        for (i = 0; i < cpf.length - 1; i++) {
            if (cpf.charAt(i) !== cpf.charAt(i + 1)) {
                equalDigits = 0;
                break;
            }
        }

        if (!equalDigits) {
            numbers = cpf.substring(0, 9);
            digits = cpf.substring(9);
            sum = 0;

            for (i = 10; i > 1; i--) {
                sum += parseInt(numbers.charAt(10 - i), 10) * i;
            }

            result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

            if (result !== parseInt(digits.charAt(0), 10)) {
                return false;
            }

            numbers = cpf.substring(0, 10);
            sum = 0;

            for (i = 11; i > 1; i--) {
                sum += parseInt(numbers.charAt(11 - i), 10) * i;
            }

            result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

            return result === parseInt(digits.charAt(1), 10);
        } else {
            return false;
        }
    }

    private static isValidCnpj(cnpj: string): boolean {
        if (cnpj.length !== 14) {
            return false;
        }

        if (/^(\d)\1+$/.test(cnpj)) {
            return false;
        }

        let length = cnpj.length - 2;
        let numbers = cnpj.substring(0, length);
        let digits = cnpj.substring(length);
        let sum = 0;
        let pos = length - 7;

        for (let i = length; i >= 1; i--) {
            sum += parseInt(numbers.charAt(length - i), 10) * pos--;
            if (pos < 2) {
                pos = 9;
            }
        }

        let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

        if (result !== parseInt(digits.charAt(0), 10)) {
            return false;
        }

        length += 1;
        numbers = cnpj.substring(0, length);
        sum = 0;
        pos = length - 7;

        for (let i = length; i >= 1; i--) {
            sum += parseInt(numbers.charAt(length - i), 10) * pos--;
            if (pos < 2) {
                pos = 9;
            }
        }

        result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

        return result === parseInt(digits.charAt(1), 10);
    }

    /**
     * Valida se um valor do campo é igual ao outro
    */
    static compareValue(compareValue: string) {
        console.log("[genericValidator.compareValue] compareValue: ", compareValue)
        return (control: AbstractControl): Validators => {
            const fieldValue = control.value;
            if (fieldValue !== compareValue) {
                return { valueNotMatch: true };
            }
            return null;
        };
    }

    static emailValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;
            if (value) {
                const emailRegex: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                return emailRegex.test(value) ? null : { invalidEmail: true };
            }
            return null;
        };
    }
} 