import { Component, OnInit } from '@angular/core';
import { GerinusBD } from 'src/app/app-core/lib/gerinus-bd.component';
import { GlobalService } from 'src/app/app-core/lib/global.service';
import { ProcessService } from 'src/app/app-core/services/process.service';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ProcessMailService } from 'src/app/app-core/services/process-mail.service';
import { CryptoService } from 'src/app/app-core/services/crypto.service';
import { IProccess } from 'src/app/interfaces/proccess.interface';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'app-mail',
    templateUrl: './mail.component.html',
    styleUrls: ['./mail.component.scss'],
    providers: [ProcessService, Title, ProcessMailService, CryptoService, MessageService],
})
export class MailComponent extends GerinusBD implements OnInit {
    routerTitle: string;
    routerActive: string;
    processId: string;
    process: IProccess;
    favoriteIconClicked: boolean = false;

    constructor(
        private route: ActivatedRoute,
        public global: GlobalService,
        public processMailService: ProcessMailService,
        private cryptoService: CryptoService,
        private title: Title,
        private messageService: MessageService,
    ) {
        super(global, processMailService);
    }

    ngOnInit(): void {
        this.afterCreate();

        this.filters = [
            {
                name: 'Assunto',
                columName: 'PMAIL_Assunto',
            },
            {
                name: 'Data',
                columName: 'PMAIL_created_at',
                type: 'date',
            },
            {
                name: 'Nome',
                columName: 'PE_Nome',
            },
        ];

        const params = this.route.snapshot.paramMap;
        this.processId = this.cryptoService.decrypt(String(params.get('id')));

        this.route.paramMap.subscribe((params) => {
            this.listAll();

            this.routerActive = params.get('type');
            this.title.setTitle(this.getTitle(params.get('type')));
            this.routerTitle = this.getTitle(params.get('type'));
        });
    }

    getTitle(routerType: string) {
        const title = {
            received: 'Recebidos',
            sent: 'Enviados',
            favorited: 'Favoritos',
            archived: 'Arquivados',
        };

        return title[routerType];
    }

    public async onMarkMessage(event: Event, messageId: number, type: 'favorited' | 'archived') {
        event.stopPropagation();

        await this.processMailService
            .markMessage({
                SPMSG_Tipo: type,
                SPMSG_CodigoUsuario: this.global.user().USR_CodigoUsuario,
                SPMSG_CodigoMensagem: messageId,
                SPMSG_CodigoProcesso: Number(this.processId),
            })
            .subscribe({
                next: () => {
                    const action = type === 'favorited' ? 'favoritada' : 'arquivada';

                    this.showToast(`Mensagem ${action} com sucesso`);
                    this.listAll();
                },
            });
    }

    public async onUnMarkMessage(messageId: number, type: 'favorited' | 'archived') {
        await this.processMailService.unMarkMessage(Number(messageId)).subscribe({
            next: () => {
                this.listAll();
            },
        });
    }

    public listCondition() {
        switch (this.routerActive) {
            case 'received': {
                return `"PMSG_CodigoDestinatario" = ${this.global.user().USR_CodigoUsuario} and "PMSG_CodigoProcesso" = ${this.processId} and ("SPMSG_Tipo" IS NULL OR "SPMSG_Tipo" != 'archived' OR "SPMSG_Tipo" = 'favorited')`;
            }
            case 'sent': {
                return `"PMSG_CodigoRemetente" = ${this.global.user().USR_CodigoUsuario} and "PMSG_CodigoProcesso" = ${this.processId} and ("SPMSG_Tipo" IS NULL OR "SPMSG_Tipo" != 'archived' OR "SPMSG_Tipo" = 'favorited')`;
            }
            case 'favorited': {
                return `"SPMSG_Tipo" = 'favorited' and "SPMSG_CodigoProcesso" = ${this.processId} and "SPMSG_CodigoUsuario" = ${this.global.user().USR_CodigoUsuario}`;
            }
            case 'archived': {
                return `"SPMSG_Tipo" = 'archived' and "SPMSG_CodigoProcesso" = ${this.processId} and "SPMSG_CodigoUsuario" = ${this.global.user().USR_CodigoUsuario}`;
            }
        }
    }

    showToast(message: string) {
        this.messageService.add({
            severity: 'success',
            closable: true,
            icon: 'ph-star',
            summary: 'Sucesso',
            detail: message,
        });
    }

    onNavigateToMessage(messageId: number) {
        const encryptedMessageId = this.cryptoService.encrypt(String(messageId));

        this.global.router.navigate([`../${this.routerActive}/${encryptedMessageId}`], { relativeTo: this.route });
    }

    handleMarkMessageAsRed(messageId: number, isMessageRead: boolean) {
        if (this.routerActive === 'received' && isMessageRead === false) {
            this.processMailService.markMessageAsRead(messageId).subscribe();
        }
    }
}
