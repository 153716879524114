import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GerinusService } from '../lib/gerinus.service';
import { Observable } from 'rxjs';
import { IGetEntityProcessMailResponse } from 'src/app/interfaces/process-mail.interface';

interface IMarkMessage {
    SPMSG_CodigoMensagem: number;
    SPMSG_Tipo: 'archived' | 'favorited';
    SPMSG_CodigoUsuario: number;
    SPMSG_CodigoProcesso: number;
}

export interface IProcessMailResponse {
    success: boolean;
    message: string;
}

export interface IResponseMessageRequest {
    responseBody: string;
    messageId: number;
    senderId: number;
}
@Injectable()
export class ProcessMailService extends GerinusService {
    constructor(public http: HttpClient) {
        super(http);
        this.entityName = 'processo_mensagem';
        this.idField = 'PMSG_CodigoMensagem';
        this.model = 'ProcessoMensagemModel';
    }

    markMessage(data: IMarkMessage): Observable<IProcessMailResponse> {
        return this.http.post<IProcessMailResponse>(
            this.apiURL() + '/api/process-mail/mark-message',
            data,
            this.headers(),
        );
    }

    unMarkMessage(id: number): Observable<IProcessMailResponse> {
        return this.http.delete<IProcessMailResponse>(
            this.apiURL() + '/api/process-mail/unmark-message/' + id,
            this.headers(),
        );
    }

    markMessageAsRead(id: number) {
        return this.http.put(this.apiURL() + '/api/process-mail/mark-as-read/' + id, null, this.headers());
    }

    sendResponse(data: IResponseMessageRequest): Observable<IProcessMailResponse> {
        return this.http.post<IProcessMailResponse>(
            this.apiURL() + '/api/process-mail/send-message-response/',
            data,
            this.headers(),
        );
    }

    // sendMail() {
    //     return this.http.post(this.apiURL() + '/api/send-mail', null, this.headers());
    // }
}
