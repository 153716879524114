import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GlobalService } from './global.service';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';

@Injectable()
export class GerinusService {
    public controller = 'crud';
    public model = 'BaseModel';
    public entityName = null;
    public idField = null;
    public companyField = null;
    public companyID = null;
    public orderBy = null;
    public cnpj = null;

    constructor(public http: HttpClient) { }

    public apiURL() {
        return environment.apiURL;
    }

    public headers(useFormData = false) {
        let entityData: any = {
            model: this.model,
            entityName: this.entityName,
            idField: this.idField,
            orderBy: this.orderBy,
        };

        let user: any = localStorage.getItem('user');
        if (user) user = JSON.parse(user);

        if (user) {
            entityData.Gerinus = btoa(JSON.stringify(user));
        }

        let header = {
            'Content-Type': 'application/json',
            EntityData: JSON.stringify(entityData),
            Authorization: `Bearer ${user?.USR_Token?.token}`,
        };

        if (useFormData) {
            delete header['Content-Type'];
        }

        return {
            headers: header,
        };
    }

    public getEntity(id) {
        return this.http.get(this.apiURL() + '/api/' + this.controller + '/get/' + id, this.headers());
    }

    public save(entity, useFormData = false) {
        let formData = null;
        if (useFormData) {
            formData = new FormData();
            let keys = Object.keys(entity);
            for (let key of keys) {
                let foto = key.substring(4, 8);
                //console.log(foto);
                if (foto == 'Foto' || entity[key] instanceof File) {
                    formData.append(key, entity[key]);
                } else {
                    if (typeof entity[key] == 'object') {
                        formData.append(key, JSON.stringify(entity[key]));
                    } else {
                        formData.append(key, entity[key]);
                    }
                }
            }
        }
        if (formData) {
            let id = entity[this.idField] ? `/${entity[this.idField]}` : '/0';
            return this.http.post(
                this.apiURL() + '/api/' + this.controller + '/save' + id,
                formData,
                this.headers(true),
            );
        } else {
            let id = entity[this.idField] ? `/${entity[this.idField]}` : '/0';
            return this.http.post(this.apiURL() + '/api/' + this.controller + '/save' + id, entity, this.headers());
        }
    }

    public delete(id) {
        return this.http.delete(this.apiURL() + '/api/' + this.controller + '/delete/' + id, this.headers());
    }

    public listAll(condition = null, page = 1) {
        let where = {};
        let pageQry = '';

        if (condition) {
            where = {
                where: condition,
            };
        }
        if (page != 1) {
            pageQry = '/' + page;
        }

        return this.http.post(this.apiURL() + '/api/' + this.controller + '/get/all' + pageQry, where, this.headers());
    }

    public async mergePdfs(principalBufferPdf: ArrayBuffer, pdfsToMerges: ArrayBuffer[], filename: string, documentNumber: string, abbreviation = "NUP") {
        let PdfPrincipal = await PDFDocument.load(principalBufferPdf);

        for (const pdfAnexo of pdfsToMerges) {
            try {
                let pdfReq = await PDFDocument.load(pdfAnexo);
                let contentPages = await PdfPrincipal.copyPages(pdfReq, pdfReq.getPageIndices());
                for (const page of contentPages) {
                    PdfPrincipal.addPage(page);
                }
            } catch (error) {
                console.error('Erro ao carregar PDF para mesclagem:', error);
            }
        }

        const totalPages = PdfPrincipal.getPageCount();

        for (let i = 0; i < totalPages; i++) {
            const page = PdfPrincipal.getPage(i);
            const { width } = page.getSize();

            page.drawText(`${abbreviation} ${documentNumber} - Página ${i + 1} de ${totalPages}`, {
                x: width / 2 - 95,
                y: 10,
                size: 10,
                color: rgb(0, 0, 0),
                font: await PdfPrincipal.embedFont(StandardFonts.Helvetica),
            });
        }



        const base64PDFmescled = await PdfPrincipal.saveAsBase64();

        const linkSource = `data:application/pdf;base64,${base64PDFmescled}`;
        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download = filename + '.pdf';
        downloadLink.click();
    }
    

    public formatCpfCnpj(cpfCnpj: string) {
        const isCPF = cpfCnpj.length === 11;

        return cpfCnpj.replace(
            isCPF ? /^(\d{3})(\d{3})(\d{3})(\d{2})$/ : /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
            isCPF ? '$1.$2.$3-$4' : '$1.$2.$3/$4-$5'
        );
    };
}
