import { Component } from '@angular/core';
import { GlobalService } from './app-core/lib/global.service';
import { InactivityService } from './app-core/services/inactivity.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    title = 'argon-dashboard-angular';

    constructor(public global: GlobalService, private inactivityService: InactivityService) {}
}
