import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/app-core/lib/global.service';
import { MenuService } from 'src/app/app-core/services/menu.service';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    providers: [MenuService],
})
export class SidebarComponent implements OnInit {
    public menuItems: any[];
    public isCollapsed = true;
    public skeletonCount = Array.from({ length: 8 });

    constructor(
        public global: GlobalService,
        public menu: MenuService,
        public router: Router,
    ) {}

    ngOnInit() {
        this.menu.sideMenu(this.global.user().USR_CodigoUsuario).subscribe({
            next: (response: any) => {
                this.menuItems = response.map((menu) => this.mapToArgon(menu));
            },
        });
    }
    

    public mapToArgon(menu) {
        if (menu) {
            return {
                url: menu.url,
                name: menu.name,
                icon: menu.icon,
                children: menu.children.map((m) => this.mapToArgon(m)),
            };
        }

        return null;
    }
}
