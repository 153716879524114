import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GerinusService } from '../lib/gerinus.service';

@Injectable()
export class OrganizationService extends GerinusService {
    constructor(public http: HttpClient) {
        super(http);
        this.entityName = 'orgao';
        this.idField = 'OR_CodigoOrgao';
        this.model = 'OrgaoModel';
    }
}
