import { Component, Input, OnChanges, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { GlobalService } from '../../lib/global.service';
import { GerinusService } from '../../lib/gerinus.service';
import { Observable, Subject } from 'rxjs';

@Component({
    selector: 'image-component',
    templateUrl: './image.component.html',
    styleUrls: ['./image.component.scss', '../shared.css'],
    providers: [GerinusService],
})
export class ImageComponent implements OnChanges {
    @ViewChild('tirarFoto') tirarFoto;

    @Input() entity;
    @Input() field;
    @Input() height = 250;
    @Input() buttonText = 'Abrir Imagem';
    @Input() takePhoto = false;

    trigger = new Subject();

    public stream;
    public permissionDenied;
    public tmpField;
    public tmp;
    public uploading: boolean = false;

    constructor(
        public global: GlobalService,
        public service: GerinusService,
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.entity || changes.field) {
            if (this.entity || this.field) {
                let fieldPath = this.field.split('.');
                let target = this.entity;
                for (let i = 0; i < fieldPath.length - 1; i++) {
                    target = target[fieldPath[i]];
                }
                this.tmp = target;
                this.tmpField = fieldPath[fieldPath.length - 1];
                console.log('IMAGE', this.tmp, this.tmpField, fieldPath);
            }
        }
    }

    public loadImage(file) {
        this.uploading = true;
        let data = new FormData();

        if (file?.target?.files.item(0)) {
            data.append('file', file.target.files.item(0));
        } else {
            data.append('file', file);
        }

        this.service.http
            .post(this.service.apiURL() + '/api/app/file', data, this.service.headers(true))
            .subscribe((response: any) => {
                if (response.ok) {
                    this.tmp[this.tmpField] = response.files[0];
                }
                this.uploading = false;
            });
    }

    get $trigger() {
        return this.trigger.asObservable();
    }

    public checkPermissions() {
        this.global.startLoading();
        navigator.mediaDevices
            .getUserMedia({
                video: true,
            })
            .then((res) => {
                this.stream = res.getTracks()[0];
            })
            .catch((err) => {
                if (err.message === 'Permission denied') {
                    this.permissionDenied = 'Acesso a câmera negada';
                }
            })
            .finally(() => {
                this.global.openModal(this.tirarFoto, 'tirarFoto');
                this.global.stopLoading();
            });
    }
}
