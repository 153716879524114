import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import FormItbi from '../form-itbi/formItbi';
import { CepService } from 'src/app/app-core/lib/cep.service';
import { FiscoService } from 'src/app/app-core/services/fisco.service';
import { GlobalService } from 'src/app/app-core/lib/global.service';

@Component({
  selector: 'app-request-itbi',
  templateUrl: './request-itbi.component.html',
  styleUrls: ['./request-itbi.component.scss'],
  providers: [FiscoService, CepService]
})
export class RequestItbiComponent extends FormItbi implements OnInit, OnChanges {

  @Input() subject;
  @Input() applicantDetails;

  public listFields = [
    "SOLIT_AreaTerreno",
    "SOLIT_AreaEdificadaTotal",
    "SOLIT_Complemento",
    "SOLIT_Logradouro",
    "SOLIT_Numero",
  ];

  public descriptionItbi = [
    { title: "I", value: "I - A AQUISIÇÃO DE GLEBA RURAL DE ÁREA NÃO EXCEDENTE A 5 (CINCO) HECTARES, QUE SE DESTINE À EXPLORAÇÃO PRÓPRIA, PELO ADQUIRENTE E SUA FAMÍLIA, NÃO POSSUINDO   ESTE IMÓVEL NO MUNICÍPIO;" },
    { title: "II", value: "II- A TRANSMISSÃO DECORRENTE DE EXECUÇÃO DE PLANOS DE HABITAÇÃO PARA POPULAÇÃO DE BAIXA RENDA, NOS TERMOS DEFINIDOS PELA LEGISLAÇÃO FEDERAL, PATROCINADO OU EXECUTADO  POR ÓRGÃOS PÚBLICOS E SEUS AGENTES;" },
    { title: "III", value: "III- A TRANSMISSÃO DE IMÓVEL RESIDENCIAL, QUANDO ADQUIRIDO POR SERVIDOR MUNICIPAL, ATIVO OU INATIVO, SEUS FILHOS MENORES OU INCAPAZES, BEM COMO AO CÔNJUGUE SOBREVIVENTE, ENQUANTO NÃO CONTRAIR NÚPCIAS, NÃO POSSUAM OUTRO IMÓVEL NO MUNICÍPIO  E O FAÇAM PARA SUA MORADIA, DESDE QUE O VALOR DO IMÓVEL NÃO SEJA SUPERIOR A 100.000 (CEM MIL) UFIRSAS. " },
    { title: "IV", value: "IV - AS TRANSFERÊNCIAS DE IMÓVEIS DESAPROPRIADAS PARA FINS DE REFORMA AGRÁRIA." },
  ]

  public listEstadoCivil = ['Solteiro', 'Casado', 'Viúvo', 'Divorciado', 'Separado'];

  public naturezaDatransacao = [
    "Compra e Venda",
    "Cessão de Posse",
    "Alienação",
    "Alienação Fiduciária",
    "Anuência",
    "Cessão de Direitos",
    "Cessão de Direitos Hereditários",
    "Cessão de Posse e Transferência De Direitos Hereditários",
    "Cisão",
    "Compra e Venda com Anuência",
    "Consolidação da Propriedade",
    "Consolidação de Garantia",
    "Dação em Pagamento",
    "Desapropriação",
    "Direito de Posse",
    "Distrato",
    "Distrato Social",
    "Divorcio Consensual",
    "Incorporação ao Patrimônio",
    "Partilha de Bens",
    "Permuta",
    "Separação de Bens",
    "Separação Judicial Consensual",
    "Transferência de Direito Hereditários",
    "Transmissão de Bens ao Cônjuge",
    "Título de Domínio",
    "Usufruto",
    "Cisão Parcial",
    "Distrato de Doação",
    "Arrematação",
    "Instituição de servidão administrativa",
    "Desincorporação de Bens Imóveis em Razão da Extinção de Empresa",
    "Cessão de transferência",
    "Integralização ao Patrimônio",
    "Cessão de direitos de aquisição",
    "Concessão de Direito Real de Superfície"
  ];

  constructor(
    public fb: FormBuilder,
    public cepService: CepService,
    public fiscoService: FiscoService,
    public global: GlobalService
  ) {
    super(fiscoService, global);
    this.form = this.fb.group({
      SOLIT_SolicitanteNomeRazaoSocial: [''],
      SOLIT_SolicitanteCpfCnpj: [''],
      SOLIT_SolicitanteTelefone: [''],
      SOLIT_SolicitanteUF: [''],
      SOLIT_SolicitanteMunicipio: [''],
      SOLIT_SolicitanteLogradouro: [''],
      SOLIT_SolicitanteNumero: [''],
      SOLIT_SolicitanteBairro: [''],
      SOLIT_SolicitanteCEP: [''],
      SOLIT_SolicitanteComplemento: [''],
      SOLIT_Logradouro: [''],
      SOLIT_Numero: [''],
      SOLIT_Bairro: [''],
      SOLIT_CEP: [''],
      SOLIT_Inscricao: [''],
      SOLIT_SolicitanteConjugue: [''],
      SOLIT_SolicitanteEstadoCivil: [''],
      SOLIT_SolicitanteRG: [''],
      SOLIT_SolicitanteEmail: [''],
      SOLIT_TipoImovel: ['Urbano'],
      SOLIT_NaturezaTransacao: [null],
      SOLIT_ValorDeclarado: [''],
      SOLIT_AreaTerreno: [''],
      SOLIT_AreaEdificadaTotal: [''],
      SOLIT_Complemento: [''],
      SOLIT_Descricao: ['']
    });
    this.inscricaoField = 'SOLIT_Inscricao';
    this.tipoImovelField = 'SOLIT_TipoImovel';
    this.enableDisableListField = [
      "SOLIT_AreaTerreno",
      "SOLIT_AreaEdificadaTotal",
      "SOLIT_Complemento",
      "SOLIT_Logradouro",
      "SOLIT_Numero",
      "SOLIT_Bairro",
      "SOLIT_CEP",
    ];
    this.handleFindCep();
  }

  ngOnInit(): void {
    this.disabledFields(this.enableDisableListField);
    this.resettingValuesChangingPropertyType();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.applicantDetails) {
      this.changeDataRequester();
    }
  }

  public getAddressApplicantData(event) {
    this.form.patchValue({
      SOLIT_SolicitanteMunicipio: event.localidade,
      SOLIT_SolicitanteBairro: event.bairro,
      SOLIT_SolicitanteLogradouro: event.logradouro,
      SOLIT_SolicitanteUF: event.uf,
      SOLIT_SolicitanteNumero: event.numero,
      SOLIT_SolicitanteComplemento: event.complemento,
      SOLIT_SolicitanteCEP: event.CEP
    });
    this.form.updateValueAndValidity();
  }

  public changeDataRequester() {
    this.form?.patchValue({
      SOLIT_SolicitanteCpfCnpj: this.applicantDetails.SOLR_CpfCnpj,
      SOLIT_SolicitanteEmail: this.applicantDetails.SOLR_Email,
      SOLIT_SolicitanteNomeRazaoSocial: this.applicantDetails.SOLR_NomeRazaoSocial
    });
    this.form.updateValueAndValidity();
  }

  private handleFindCep() {
    this.form.get('SOLIT_CEP').valueChanges.subscribe({
      next: (value: string) => {
        if (value?.length > 7) {
          this.cepService.getEnderecoByCep(value).subscribe({
            next: (response: any) => {
              this.form.patchValue({
                SOLIT_Bairro: response.bairro,
                SOLIT_Logradouro: response.logradouro
              })
              this.form.updateValueAndValidity();
            }
          });
        }
      }
    });
  }

  public patchValueformControl(data) {
    const patchValues = {
      SOLIT_AreaTerreno: data.imovel_area_terreno,
      SOLIT_AreaEdificadaTotal: data.imovel_area_total,
      SOLIT_Complemento: data.imovel_complemento,
      SOLIT_Logradouro: data.imovel_logradouro,
      SOLIT_Numero: data.imovel_numero,
      SOLIT_Bairro: data.imovel_bairro,
      SOLIT_CEP: data.imovel_cep,
    };
    return patchValues;
  }

  public setSolitDescription(description: string) {
    this.form.get("SOLIT_Descricao").setValue(description);
    this.form.updateValueAndValidity();
  }

}
