// autocomplete.component.ts
import { Component, EventEmitter, Output, ViewChild, ViewEncapsulation, Input, ElementRef } from '@angular/core';
import { NgbTypeahead, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, merge, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { UserService } from 'src/app/app-core/services/user.service';
import { GlobalService } from 'src/app/app-core/lib/global.service';
import { PersonService } from 'src/app/app-core/services/person.service';

@Component({
    selector: 'app-autocomplete',
    templateUrl: './autocomplete.component.html',
    styleUrls: ['./autocomplete.component.scss'],
    providers: [UserService, GlobalService, PersonService],
    encapsulation: ViewEncapsulation.None,
})
export class AutocompleteComponent {
    @Output() onSelectedItem: any = new EventEmitter();
    @Input() clearInputAfterSelection: boolean = false;
    @Input() data: any = {};
    @Input() by: string;
    @Input() placeholder: string;

    // Declare a property to hold the reference to the input element
    @ViewChild('inputElement', { static: false }) inputElement: ElementRef;

    public pessoas: any;
    public selectedItem: any = {};
    public selectedPE_CodigoPessoa: number;
    public inputData: any;
    constructor(
        public usuarioService: UserService,
        public pessoaService: PersonService,
        public global: GlobalService,
    ) {}

    @ViewChild('instance', { static: true }) instance: NgbTypeahead;
    focus$ = new Subject<string>();
    click$ = new Subject<string>();

    search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
        const inputFocus$ = this.focus$;

        return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
            map((term) =>
                term === ''
                    ? this.data.map((item) => item[this.by])
                    : this.data
                          .filter((item) => item[this.by].toLowerCase().includes(term.toLowerCase()))
                          .map((item) => item[this.by]),
            ),
        );
    };

    public onSelectItem(data: any): void {
        this.selectedItem = data;
        this.inputData = data;

        const findData = this.data.find((item) => item[this.by] === this.selectedItem.item);

        this.onSelectedItem.emit(findData);

        if (this.clearInputAfterSelection) {
            setTimeout(() => {
                this.inputData = '';
            }, 200);
        }
    }
}
