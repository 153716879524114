import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ImageComponent } from './src/image.component';
import { GerinusToolsModule } from '../lib/gerinus-tools.module';
import { SecondsToDaysPipe } from 'src/app/date-to-days-pipe.pipe';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const components = [ImageComponent, PageNotFoundComponent];

@NgModule({
    imports: [GerinusToolsModule, RouterModule],
    declarations: [...components, SecondsToDaysPipe],
    exports: [...components, SecondsToDaysPipe],
})
export class SharedModule {}
