import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { ViewProcessoSkeletonScreenComponent } from './view-process-skeleton-screen/view-processo-skeleton-screen.component';
import { MailComponent } from './mail/mail.component';
import { RouterModule } from '@angular/router';

import { TableModule } from 'primeng/table';
import { GerinusToolsModule } from 'src/app/app-core/lib/gerinus-tools.module';
import { AppCoreModule } from 'src/app/app-core/app-core.module';
import { MailComposeModalComponent } from './mail-compose-modal/mail-compose-modal.component';

import { DialogModule } from 'primeng/dialog';
import { ToastModule } from 'primeng/toast';
import { TabMenuModule } from 'primeng/tabmenu';

import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ViewMailComponent } from './view-mail/view-mail.component';
import { HeaderMailComponent } from './header-mail/header-mail.component';
import { SafeHtmlPipe } from 'src/app/safe-html.pipe';
import { CancellationInfoComponent } from './cancellation-info/cancellation-info.component';
import { ArchivingInfoComponent } from './archiving-info/archiving-info.component';

@NgModule({
    declarations: [
        ViewProcessoSkeletonScreenComponent,
        MailComponent,
        MailComposeModalComponent,
        ViewMailComponent,
        HeaderMailComponent,
        SafeHtmlPipe,
        CancellationInfoComponent,
        ArchivingInfoComponent,
    ],
    imports: [
        CommonModule,
        NgxSkeletonLoaderModule,
        RouterModule,
        TableModule,
        GerinusToolsModule,
        AppCoreModule,
        DialogModule,
        EditorModule,
        NgbModule,
        ToastModule,
        TabMenuModule,
        ToastModule
    ],
    exports: [
        ViewProcessoSkeletonScreenComponent, 
        CancellationInfoComponent, 
        ArchivingInfoComponent, 
        GerinusToolsModule, 
        AppCoreModule, 
        EditorModule
    ],
    providers: [{ provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }],
})
export class ViewProcessComponentsModule {}
