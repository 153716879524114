import { Component, OnInit } from '@angular/core';

import { GlobalService } from 'src/app/app-core/lib/global.service';
import { DashboardService } from 'src/app/app-core/services/dashboard.service';

@Component({
    selector: 'dashboard-admin',
    templateUrl: './dashboard-admin.component.html',
    styleUrls: ['../../pages/dashboard/dashboard.component.scss'],
    providers: [DashboardService],
})
export class DashboardAdminComponent implements OnInit {
    constructor(
        public global: GlobalService,
        public dashboardService: DashboardService,
    ) { }

    public isChartLoading: boolean = true;

    public requestsAmount: number;
    public processAmount: number;
    public organizationsAmount: number;
    public usersAmount: number;

    public requestsInEvaluation: number;
    public requestsInCorrection: number;
    public AcceptedRequests: number;

    public resolvedProcess: number;
    public archivedProcess: number;
    public processWaitingEvaluation: number;
    public processInEvaluation: number;
    public processWaitingCorrection: number;

    async ngOnInit() {
        this.requestsAmount = await this.dashboardService.getTotal('requestService');
        this.organizationsAmount = await this.dashboardService.getTotal('organizationService');
        this.usersAmount = await this.dashboardService.getTotal('userService');

        this.requestsInEvaluation = await this.dashboardService.getTotal('requestService', '"SOL_Status" = 0');

        this.requestsInCorrection = await this.dashboardService.getTotal('requestService', '"SOL_Status" = 2');

        this.AcceptedRequests = await this.dashboardService.getTotal('requestService', '"SOL_Status" = 1');

        this.processAmount = await this.dashboardService.getTotal(
            'processService',
            `"PRT_DataFim" IS NULL`,
        );

        this.processWaitingEvaluation = await this.dashboardService.getTotal(
            'processService',
            `"Status" = 1 and "PRT_DataFim" IS NULL`,
        );

        this.processInEvaluation = await this.dashboardService.getTotal(
            'processService',
            `"Status" = 2 and "PRT_DataFim" IS NULL`,
        );

        this.processWaitingCorrection = await this.dashboardService.getTotal(
            'processService',
            `"Status" = 5 and "PRT_DataFim" IS NULL`,
        );

        this.resolvedProcess = await this.dashboardService.getTotal(
            'processService',
            `"Status" = 4 and "PRT_DataFim" IS NULL`,
        );

        this.archivedProcess = await this.dashboardService.getTotal(
            'processService',
            `"Status" = 3 and "PRT_DataFim" IS NULL`,
        );

        setTimeout(() => {
            this.isChartLoading = false;
        }, 1000);
    }
}
