import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GerinusService } from '../lib/gerinus.service';
import { RequestService } from './request.service';
import { OrganizationService } from './organization.service';
import { ProcessService } from './process.service';
import { UserService } from './user.service';
import { GlobalService } from '../lib/global.service';

@Injectable()
export class DashboardService extends GerinusService {
    constructor(
        public http: HttpClient,
        public requestService: RequestService,
        public processService: ProcessService,
        public organizationService: OrganizationService,
        public userService: UserService,
        public global: GlobalService,
    ) {
        super(http);
    }

    async getTotal(entity: string, where: string = ''): Promise<number> {
        return new Promise<number>((resolve) => {
            if (this[entity]) {
                this[entity].listAll(where, 0).subscribe({
                    next: (response: any) => {
                        const total = response.data.length;
                        resolve(total);
                    },
                });
            } else {
                resolve(0);
            }
        });
    }
}
