import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
import autoTable, { UserOptions } from 'jspdf-autotable';
import * as moment from 'moment';

export interface IDataTable {
	title?: string
	contents: IContents[],
	height?: number | null,
	columns?: number,
	distanceTable?: number,
	userOptions?: UserOptions
}
interface IContents {
	title: string,
	content: string
}

@Injectable({
	providedIn: 'root'
})
export class GenerateDocumentFormService {

	constructor() { }

	renderHeader(doc: jsPDF) {
		const imgHeader = new Image();
		imgHeader.src = '../../../assets/img/documents/header-document.jpg';
		doc.addImage(imgHeader, 'jpg', 0, 0, 220, 0);
		return doc
	}

	renderFooter(doc: jsPDF) {
		const imgFooter = new Image();
		imgFooter.src = '../../../assets/img/documents/footer-document.jpg';
		doc.addImage(imgFooter, 'jpg', 0, 260, 220, 40);
		return doc
	}

	tablePdf(dataTable: IDataTable, columnsPerRow: number = 3): HTMLTableElement {
		const table = document.createElement('table');
		const tbody = document.createElement('tbody');

		if (dataTable.title) {
			const thead = document.createElement('thead');
			const headerRow = document.createElement('tr');

			const headerCell = document.createElement('th');
			headerCell.textContent = dataTable.title;
			headerCell.setAttribute('colspan', columnsPerRow.toString());
			headerRow.appendChild(headerCell);
			thead.appendChild(headerRow);
			table.appendChild(thead);
		}

		if (dataTable.contents && dataTable.contents.length > 0) {
			let count = 0;
			let tr = document.createElement('tr');

			for (let i = 0; i < dataTable.contents.length; i++) {
				let td = document.createElement("td");
				let titleSpan = document.createElement('span');
				titleSpan.textContent = dataTable.contents[i].title;
				td.appendChild(titleSpan);
				td.innerHTML += dataTable.contents[i].content;

				tr.appendChild(td);
				count++;

				if (count === columnsPerRow || i === dataTable.contents.length - 1) {
					tbody.appendChild(tr);
					tr = document.createElement('tr');
					count = 0;
				}
			}
		}
		table.appendChild(tbody);

		return table;
	}

	public haveSubject(listSubject: any[], subject: any): boolean {
		return listSubject.includes(subject);
	}

	public generateTable(doc: jsPDF, data: IDataTable[]) {
		const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
		const pageHeigth = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
		const footerHeight = 40;
		let currentY = 0;
		const meses = [
			"janeiro", "fevereiro", "março", "abril", "maio", "junho",
			"julho", "agosto", "setembro", "outubro", "novembro", "dezembro"
		];
		let heightDate: number;
		const userOptionsDefault: UserOptions = {
			theme: 'plain',
			tableLineColor: '#CCCCCC',
			headStyles: {
				lineColor: '#CCCCCC',
				lineWidth: 0.5
			},
			tableLineWidth: 0.5,
			styles: {
				fillColor: '#FFFFFF',
				cellPadding: 3,
				overflow: 'linebreak',
				fontSize: 9,
				halign: 'justify'
			}
		};
		data.map((item, index) => {
			let userOption: UserOptions = (item.userOptions) ? item.userOptions : userOptionsDefault;
			autoTable(doc, {
				html: (item.columns) ? this.tablePdf(item, item.columns) : this.tablePdf(item),
				startY: (index == 0) ? pageHeigth / 5 : item.height,
				pageBreak: 'avoid',
				...userOption,
				didDrawPage: (table) => {
					if (index == data.length - 1) {
						heightDate = table.cursor.y + 6;
						doc.setFontSize(10);
						const date = moment();
						doc.text(` ${date.date()} de ${meses[date.month()]} de ${date.year()}.`, pageWidth / 1.1, heightDate, { align: 'right' });

						if (currentY + footerHeight > pageHeigth) {
							if (heightDate + footerHeight > pageHeigth) {
								doc.addPage();
								currentY = 0;
							}
						}

					}
					else {
						data[index + 1].height = table.cursor.y + ((item.distanceTable) ? item.distanceTable : 3);
					}
				},

			});
			currentY += (item.height || 0);
		})

		return doc;
	}

	public formatData(dataString: string) {
		if (dataString) {
			// Separando os componentes da data
			const [ano, mes, dia] = dataString.split('-').join('/').split('/');

			// Mapeando o número do mês para o nome do mês
			const meses = [
				'janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho',
				'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'
			];

			// Construindo a string formatada
			const dataFormatada = `${dia} de ${meses[parseInt(mes) - 1]} de ${ano}`;

			console.log("[formatData] dataFormatada: ", dataFormatada);

			if (dataFormatada != "undefined de undefined de ") {
				return dataFormatada;
			} else {
				return "";
			}
		}
	}
}
