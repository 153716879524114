import { Component, Input, Output, EventEmitter } from '@angular/core';
import { GerinusBD } from '../gerinus-bd.component';
import { GlobalService } from '../global.service';
import { GerinusService } from '../gerinus.service';

@Component({
    selector: 'gerinus-paginacao',
    templateUrl: 'paginacao.component.html',
    providers: [GerinusService, GlobalService],
})
export class PaginacaoComponent {
    @Input() pagination;
    @Input() paginate;
    @Output() changePage = new EventEmitter();

    constructor(
        public global: GlobalService,
        public service: GerinusService,
    ) {}

    public doPageChange(page) {
        let max = this.pagination.last_page;
        if (page >= 1 && page <= max) {
            this.changePage.emit(page);
        }
    }
}
